import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-donation-list',
  templateUrl: './donation-list.component.html',
  styleUrl: './donation-list.component.scss',
})
export class DonationListComponent {
  public donorList = [];
  private campaignId;
  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly donationService: DonationService,
    private readonly utilitiesService: UtilitiesService
  ) {}
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.campaignId = params['id'];
      if (this.campaignId) {
        this.getAllDonorList(this.campaignId);
      }
    });
  }

  private getAllDonorList(campaignId: string) {
    this.spinner.show();
    this.donationService.getAllDonation().subscribe({
      next: (result) => {
        this.utilitiesService.showSpinner(false);
        this.donorList = result
          .filter((donation) => donation.campaign_id === campaignId)
          .map((donation) => {
            return {
              ...donation,
              campaign_name: this.getCampaignName(donation),
            };
          });
      },
      error: (err) => {
        this.utilitiesService.showSpinner(false);
        this.utilitiesService.showSwal(
          'error',
          'Error to fetching the Donation List'
        );
      },
    });
  }

  private getCampaignName(donation: any): string | null {
    if (
      donation.campaign_id === '4743ea80-d69e-460a-816c-56052a320ac6' &&
      !donation.campaign_name
    ) {
      return 'Wounded Students Assistance Fund - 2024';
    } else if (
      donation.campaign_id === 'be8349a6-ba94-4285-acc1-295be0639e0a' &&
      !donation.campaign_name
    ) {
      return 'Flood Relief Fund - 2024';
    } else {
      return donation.campaign_name;
    }
  }
  public formatDate(date: string): string {
    return this.utilitiesService.formattedDateddMMyyyy(
      date,
      'dd-mmmm-yyyy',
      ' '
    );
  }
}
