<div class="main_div">
  <div class="breadcrumb">
    <span class="donation_title"> Donations Campaigns</span>
    <span> / {{ donorList[0].campaign_name }}</span>
  </div>
  <div class="header">
    <p style="font-size: 30px; margin-bottom: 0">Responses</p>
  </div>

  <div class="datagrid_container">
    <dx-data-grid
      class="donation_list_datagrid"
      id="gridContainer"
      [dataSource]="donorList"
      [allowColumnReordering]="true"
      [allowColumnResizing]="true"
      [wordWrapEnabled]="true"
      [columnAutoWidth]="false"
      [rowAlternationEnabled]="true"
      rowTemplate="rowTemplateName"
      [showBorders]="true"
    >
      <dxo-scrolling mode="virtual" useNative="false"></dxo-scrolling>

      <dxi-column
        caption="Date"
        dataField="created_at"
        alignment="left"
        [width]="185"
      ></dxi-column>
      <dxi-column
        caption="Campaign"
        dataField="campaign_name"
        alignment="left"
        [width]="450"
      ></dxi-column>
      <dxi-column
        caption="Amount"
        dataField="amount"
        alignment="left"
        [width]="150"
      ></dxi-column>
      <dxi-column
        caption="Status"
        dataField="status"
        alignment="left"
        [width]="200"
      ></dxi-column>
      <dxi-column caption="Receipt" alignment="left" [width]="170"></dxi-column>
      <dxi-column caption="Share" alignment="left" [width]="300"></dxi-column>

      <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
        <tr class="custom-row">
          <td style="font-weight: 400; padding-left: 12px">
            {{ formatDate(row?.data?.created_at) }}
          </td>
          <td class="campaign_name">
            {{ row?.data?.campaign_name }}
          </td>
          <td style="padding-left: 14px; color: #272727">
            <div class="amount">৳{{ row?.data?.amount }}</div>
          </td>
          <td
            [ngClass]="{
              'state-received': row?.data?.state === 'RECEIVED',
              'state-disbursed': row?.data?.state === 'DISBURSED',
              'state-interested-requested':
                row?.data?.state === 'INTERESTED' ||
                row?.data?.state === 'REQUESTED'
            }"
          >
            <ul class="state_list" style="margin: 0">
              <li class="state capitalize-first-letter">
                {{ row?.data?.state }}
              </li>
            </ul>
          </td>
          <td>
            <button
              *ngIf="
                row?.data?.state === 'RECEIVED' ||
                row?.data?.state === 'DISBURSED'
              "
              class="blue-button-sm radius_8"
              style="max-width: 156px"
            >
              Download Receipt
            </button>
            <div
              style="padding-left: 12px"
              *ngIf="
                row?.data?.state != 'RECEIVED' ||
                row?.data?.state != 'DISBURSED'
              "
            >
              No Data
            </div>
          </td>
          <td>
            <div
              style="padding-left: 12px; color: #dc6803"
              *ngIf="row?.data?.state != 'DISBURSED'"
            >
              <img
                loading="lazy"
                alt="warning"
                src="../../../../assets/images/icon/warning_fill_white_icon.svg"
              />
              Photos/Videos will be available after disbursement
            </div>
            <div>
              <button
                class="yellow-button-sm"
                *ngIf="row?.data?.state === 'DISBURSED'"
              >
                Share Photos/Videos
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </dx-data-grid>
  </div>
</div>
