<div class="title">Projects</div>

<div class="list_item" *ngFor="let project of projects">
  <div class="name_div">
    <img src="/assets/images/icon/project_icon_colorful.svg" />
    <div>
      <div class="name">{{ project?.name }}</div>
      <div class="name">{{ project?.program_name }}</div>
    </div>
  </div>
  <div class="d-flex gap-5">
    <div>
      <span class="c_939393">Status: </span
      ><span>{{ project?.is_active ? "In Progress" : "Stopped" }}</span>
    </div>
    <div>
      <span class="c_939393">Created on: </span
      ><span>{{ project?.created_at | date : "dd MMM,yyyy" }}</span>
      <img
        class="cursor ml-2"
        src="/assets/images/icon/more_vertical_icon.svg"
      />
    </div>
  </div>
</div>
