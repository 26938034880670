<div class="main_div">
  <div
    class="ngo_logo_div"
    [ngStyle]="{
      'background-image':
        'url(' + utilitiesService.getUrl(ngo_details?.ngo_profile_img_url) + ')'
    }"
  >
    <div class="ngo_logo">
      <img
        [width]="218"
        [height]="218"
        [src]="utilitiesService.getUrl(ngo_details?.logo_url)"
        alt="Ngo logo"
      />
    </div>
  </div>

  <div class="first_div">
    <div class="ngo mt-3">{{ ngo_details?.name || "Not available" }}</div>

    <div *ngIf="ngo_details?.is_verified" class="verified">
      <img src="/assets/images/icon/verified_icon_green.svg" /><span
        >Verified NGO in Bangladesh and International</span
      >
    </div>
    <div class="info">
      <div>
        <img src="/assets/images/icon/file_check_icon.svg" /><span
          >Reg. No: {{ ngo_details?.reg_no || "Not available" }}</span
        >
      </div>
      <div>
        <img src="/assets/images/icon/calendar_x_icon.svg" /><span
          >Valid upto:
          {{
            (ngo_details?.valid_till | date : "dd-MMM-yy") || "Not available"
          }}</span
        >
      </div>
    </div>
    <div class="info">
      <div>
        <img src="/assets/images/icon/calendar_check_icon.svg" /><span
          >Reg. Date:
          {{
            (ngo_details?.reg_at | date : "dd-MMM-yy") || "Not available"
          }}</span
        >
      </div>
      <div>
        <img src="/assets/images/icon/square_chart_gantt_icon.svg" /><span
          >Renewed on:
          {{
            (ngo_details?.renewed_at | date : "dd-MMM-yy") || "Not available"
          }}</span
        >
      </div>
    </div>
    <div class="mb-3">
      <img class="icon" src="/assets/images/icon/location_icon.svg" /><span
        style="opacity: 0.8"
        >{{
          ngo_details?.address ||
            "Not available" + ", " + ngo_details?.country ||
            "Not available"
        }}</span
      >
    </div>
    <div class="info">
      <div>
        <img src="/assets/images/icon/calling_icon.svg" /><span>{{
          ngo_details?.phone || "Not available"
        }}</span>
      </div>
      <div>
        <img src="/assets/images/icon/mail_icon.svg" /><span>{{
          ngo_details?.email || "Not available"
        }}</span>
      </div>
      <div>
        <img src="/assets/images/icon/web_icon.svg" /><span>{{
          ngo_details?.website || "Not available"
        }}</span>
      </div>
    </div>
    <div class="mb-3 mt-2">Find Us</div>
    <div class="social">
      <img src="/assets/images/logo/facebook_logo.svg" />
      <img src="/assets/images/logo/twitter_logo.svg" />
      <img src="/assets/images/logo/youtube_logo.svg" />
      <img src="/assets/images/logo/linkedin_logo.svg" />
    </div>

    <div class="tab">
      <div
        *ngFor="let tab of tabPane; let i = index"
        class="tab_btn"
        [class.active]="getActiveTab(i)"
        (click)="setActiveTab(i)"
      >
        {{ tab?.text }}
      </div>
    </div>
  </div>

  <div class="first_div mt-4">
    <div class="ngo text-dark">{{ activeTab?.text }}</div>
    <img src="" />
    <div *ngIf="activeTab?.no !== 4" class="main_div">
      {{ ngo_details[activeTab?.obj] || "Not Available!" }}
    </div>
    <img
      class="w-100"
      *ngIf="activeTab?.no === 4"
      [src]="ngo_details[activeTab?.obj]"
      alt="Award image"
    />
  </div>
</div>
