import { Component } from '@angular/core';

@Component({
  selector: 'app-donor-dashboard',
  templateUrl: './donor-dashboard.component.html',
  styleUrl: './donor-dashboard.component.scss',
})
export class DonorDashboardComponent {
  public activeTab: number = 1;
  public showDonationPopUp = false;
  public showRecentDonationPopUp = false;
  public startDate: Date = new Date(2023, 0, 1);
  public endDate: Date = new Date(2024, 11, 1);
  public donation: any[] = [
    {
      cover_img_vdo:
        'https://shohay-bucket.s3.amazonaws.com/bada1d4e-4547-4faa-9f43-f1d8864afc8e',
      name: 'Zaid Rahman',
      total_raised: '15,20,00,000',
      team_member: '57',
    },
    {
      cover_img_vdo:
        'https://shohay-bucket.s3.amazonaws.com/bada1d4e-4547-4faa-9f43-f1d8864afc8e',
      name: 'Zaid Rahman',
      total_raised: '15,20,00,000',
      team_member: '57',
    },
    {
      cover_img_vdo:
        'https://shohay-bucket.s3.amazonaws.com/bada1d4e-4547-4faa-9f43-f1d8864afc8e',
      name: 'Zaid Rahman',
      total_raised: '15,20,00,000',
      team_member: '57',
    },
    {
      cover_img_vdo:
        'https://shohay-bucket.s3.amazonaws.com/bada1d4e-4547-4faa-9f43-f1d8864afc8e',
      name: 'Zaid Rahman',
      total_raised: '15,20,00,000',
      team_member: '57',
    },
    {
      cover_img_vdo:
        'https://shohay-bucket.s3.amazonaws.com/bada1d4e-4547-4faa-9f43-f1d8864afc8e',
      name: 'Zaid Rahman',
      total_raised: '15,20,00,000',
      team_member: '57',
    },
    {
      cover_img_vdo:
        'https://shohay-bucket.s3.amazonaws.com/bada1d4e-4547-4faa-9f43-f1d8864afc8e',
      name: 'Zaid Rahman',
      total_raised: '15,20,00,000',
      team_member: '57',
    },
    {
      cover_img_vdo:
        'https://shohay-bucket.s3.amazonaws.com/bada1d4e-4547-4faa-9f43-f1d8864afc8e',
      name: 'Zaid Rahman',
      total_raised: '15,20,00,000',
      team_member: '57',
    },
    {
      cover_img_vdo:
        'https://shohay-bucket.s3.amazonaws.com/bada1d4e-4547-4faa-9f43-f1d8864afc8e',
      name: 'Zaid Rahman',
      total_raised: '15,20,00,000',
      team_member: '57',
    },
    {
      cover_img_vdo:
        'https://shohay-bucket.s3.amazonaws.com/bada1d4e-4547-4faa-9f43-f1d8864afc8e',
      name: 'Zaid Rahman',
      total_raised: '15,20,00,000',
      team_member: '57',
    },
    {
      cover_img_vdo:
        'https://shohay-bucket.s3.amazonaws.com/bada1d4e-4547-4faa-9f43-f1d8864afc8e',
      name: 'Zaid Rahman',
      total_raised: '15,20,00,000',
      team_member: '57',
    },
    {
      cover_img_vdo:
        'https://shohay-bucket.s3.amazonaws.com/bada1d4e-4547-4faa-9f43-f1d8864afc8e',
      name: 'Zaid Rahman',
      total_raised: '15,20,00,000',
      team_member: '57',
    },
  ];
  public recentDonations = [
    {
      donorName: 'Nusrat Begum',
      donorId: '#1943736193749',
      campaign: 'Aid Appeal for Wounded Village Student',
      date: '03 June 2025',
      time: '11:50 PM',
      paymentMethod: 'Bank Transfer',
      donationAmount: '51,000',
    },
    {
      donorName: 'Anis Islam',
      donorId: '#1943736193749',
      campaign: 'Emergency Relief for Flood Victims',
      date: '03 June 2025',
      time: '11:50 PM',
      paymentMethod: 'Bank Transfer',
      donationAmount: '51,000',
    },
    {
      donorName: 'Yasmin Khan',
      donorId: '#1943736193749',
      campaign: 'Medical Supplies for Rural Health Centers',
      date: '03 June 2025',
      time: '11:50 PM',
      paymentMethod: 'Bank Transfer',
      donationAmount: '51,000',
    },
    {
      donorName: 'Nafis Khan',
      donorId: '#1943736193749',
      campaign: 'Scholarship Program for Underprivileged Students',
      date: '03 June 2025',
      time: '11:50 PM',
      paymentMethod: 'Bank Transfer',
      donationAmount: '51,000',
    },
    {
      donorName: 'Ishaan Akhtar',
      donorId: '#1943736193749',
      campaign: 'Winter Clothes Drive for the Homeless',
      date: '03 June 2025',
      time: '11:50 PM',
      paymentMethod: 'Bank Transfer',
      donationAmount: '51,000',
    },
    {
      donorName: 'Amira Karim',
      donorId: '#1943736193749',
      campaign: 'Clean Water Initiative for Remote Villages',
      date: '03 June 2025',
      time: '11:50 PM',
      paymentMethod: 'Bank Transfer',
      donationAmount: '51,000',
    },
    {
      donorName: 'Fahim Sheikh',
      donorId: '#1943736193749',
      campaign: 'Rehabilitation Support for Displaced Families',
      date: '03 June 2025',
      time: '11:50 PM',
      paymentMethod: 'Bank Transfer',
      donationAmount: '51,000',
    },
  ];
  public donations = [
    {
      name: 'Maksudur Rahman',
      id: '1943736193749',
      campaign: 'Aid Appeal for Wounded Village Student',
      date: '05 June 2025',
      amount: 5000,
    },
    {
      name: 'Maksudur Rahman',
      id: '1943736193749',
      campaign: 'Aid Appeal for Wounded Village Student',
      date: '05 June 2025',
      amount: 5000,
    },
    {
      name: 'Maksudur Rahman',
      id: '1943736193749',
      campaign: 'Aid Appeal for Wounded Village Student',
      date: '05 June 2025',
      amount: 5000,
    },
    {
      name: 'Maksudur Rahman',
      id: '1943736193749',
      campaign: 'Aid Appeal for Wounded Village Student',
      date: '05 June 2025',
      amount: 5000,
    },
    {
      name: 'Maksudur Rahman',
      id: '1943736193749',
      campaign: 'Aid Appeal for Wounded Village Student',
      date: '05 June 2025',
      amount: 5000,
    },
  ];

  constructor() {}
  ngOnInit(): void {}

  public getActiveTab(tab: number): boolean {
    return tab === this.activeTab;
  }

  public setActiveTab(tab: number): void {
    this.activeTab = tab;
  }

  public get donorFiltering(): any[] {
    return this.donation.slice(0, 6);
  }
  public closePopup() {
    this.showDonationPopUp = false;
    this.showRecentDonationPopUp = false;
  }

  public openTopDonorList() {
    this.showDonationPopUp = true;
  }
  public openRecentDonorList() {
    this.showRecentDonationPopUp = true;
  }
}
