<div class="main_div">
  <div>{{ currentUrl }}</div>
  <div class="second_div">
    <img
      class="click"
      title="Info"
      src="./assets/images/icon/info_icon_inactive.svg"
    />
    <img
      class="click"
      title="Notifications"
      src="./assets/images/icon/notification_icon_inactive.svg"
    />
    <div class="dropdown">
      <button
        class="btn btn-secondary dropdown-toggle custom-dropdown-toggle"
        type="button"
        [title]="sessionUser.name"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ userNameFirstLetter }}
      </button>
      <ul
        class="dropdown-menu dropdown_menu"
        aria-labelledby="dropdownMenuButton1"
      >
        @if(sessionUser.role.short_form !== 'system_admin') {
        <li>
          <a class="dropdown-item" href="#"
            ><img class="c" src="./assets/images/icon/profile_icon_black.svg" />
            My Profile</a
          >
        </li>
        <li><hr class="dropdown-divider" /></li>
        }
        <li>
          <a class="dropdown-item" href="#"
            ><img
              class="c"
              src="./assets/images/icon/settings_icon_black.svg"
            />
            Account Setting</a
          >
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li>
          <a class="dropdown-item" [routerLink]="'/auth/change-password'"
            ><img class="c" src="./assets/images/icon/lock_icon_black.svg" />
            Change Password</a
          >
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li>
          <a class="dropdown-item" (click)="logOut()"
            ><img class="c" src="./assets/images/icon/logout_icon_black.svg" />
            Logout</a
          >
        </li>
        <!-- <li><a class="dropdown-item" href="#">Something else here</a></li>    -->
        <!-- here -->
      </ul>
    </div>
  </div>
</div>
