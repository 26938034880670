<div class="main_div">
  <div class="breadcrumb">
    <span class="donation_title"> Campaign Management / </span>
    <span> Requested Campaigns </span>
  </div>

  <div class="header">
    <div class="header_text">
      <div class="donation_camp_text">Requested Campaigns</div>
    </div>
  </div>

  <!-- panel-title -->
  <div class="full_panel row d-flex justify-content-between align-items-center">
    <div class="col d-flex p-0">
      <dx-tab-panel
        (onSelectionChanged)="onTabChange($event)"
        [selectedIndex]="activeTabIndex"
        style="box-shadow: none"
        [swipeEnabled]="true"
      >
        <dxi-item
          title="Pending"
          [icon]="
            activeTabIndex === 0
              ? './assets/images/icon/pending-acitve.svg'
              : './assets/images/icon/pending-inactive.svg'
          "
        >
        </dxi-item>
        <dxi-item
          title="Approved"
          [icon]="
            activeTabIndex === 1
              ? './assets/images/icon/approve-active.svg'
              : './assets/images/icon/approve-inactive.svg'
          "
        >
        </dxi-item>
        <dxi-item
          title="Rejected"
          [icon]="
            activeTabIndex === 2
              ? './assets/images/icon/closed-active.svg'
              : './assets/images/icon/closed-inactive.svg'
          "
        >
        </dxi-item>
      </dx-tab-panel>
    </div>
    <div class="col panel_right_side d-flex gap-2 justify-content-end">
      <dx-text-box
        class="search_box"
        placeholder="Filter By keyword"
        [showClearButton]="true"
        [buttons]="[
          {
            name: 'searchCampaign',
            location: 'before',
            options: {
              icon: 'find',
              elementAttr: {
                class: 'searchForm'
              }
            }
          }
        ]"
      >
        <dx-validator validationGroup="validationGrp"> </dx-validator>
      </dx-text-box>
    </div>
  </div>
  <!-- panel-title-end -->

  <div class="panel_content_for_my_campaign">
    @if(filteredCampaigns.length > 0){
    <div
      class="campaign_list cursor"
      *ngFor="let campaign of filteredCampaigns"
      (click)="
        showPopUp(
          campaign.ngo_id,
          campaign.project_id,
          campaign.id,
          campaign?.attribute?.id
        )
      "
    >
      <div class="ngo_organizer_inf">
        <div class="ngo_inf">
          <div class="ngo_img">
            <img
              loading="lazy"
              [src]="
                campaign?.attribute?.ngo_logo_url ||
                '/assets/images/claim_your_NGO.png'
              "
              alt="ngo_img"
            />
          </div>
          <div class="ngo_title">
            <div class="NGO">Ngo</div>
            <div class="title_">
              {{
                campaign?.attribute?.ngo_name ||
                  "/assets/images/icon/campaing_img.svg"
              }}
            </div>
          </div>
        </div>

        <div class="organizer_inf">
          <div class="ngo_img_org">
            <img
              loading="lazy"
              [src]="
                campaign?.attribute?.organizer_profile_img ||
                '/assets/images/round_profile.png'
              "
              alt="organizer_img"
            />
          </div>
          <div class="ngo_title">
            <div class="title_">
              {{ campaign?.attribute?.organizer_name || "Not available!" }}
            </div>
            <div class="organizer_">
              Organizer |
              {{ campaign?.attribute?.organizer_contact || "Not available!" }}
            </div>
          </div>
        </div>

        <div class="status_label">
          Campaigns Status:
          <div
            [ngStyle]="{
              color:
                campaign.status === approvalStatusEnum.PENDING
                  ? '#0054a6'
                  : campaign.status === approvalStatusEnum.ACCEPTED
                  ? 'green'
                  : 'red'
            }"
            class="status"
          >
            {{ campaign.status }}
          </div>
        </div>
      </div>

      <hr class="hr" />

      <div class="ngo_organizer_inf">
        <div class="ngo_inf_">
          <div class="campaign_img">
            <img
              loading="lazy"
              [src]="campaign?.attribute?.cover_img_vdo"
              alt="cover_img"
              srcset=""
            />
          </div>
          <div class="ngo_title">
            <div class="NGO">
              <span class="pending">{{ campaign?.attribute?.subtitle }}</span>
            </div>
            <div class="title_campaign">
              {{ campaign?.attribute?.title }}
            </div>
          </div>
        </div>

        <div class="organizer_inf_ timeline_">
          <div class="ngo_img">
            <img
              loading="lazy"
              src="./assets/images/icon/campaign-target.svg"
              alt="organizer_img"
            />
          </div>
          <div class="ngo_title">
            <div class="title_">Donation Target</div>
            <div class="organizer_">
              ৳
              {{
                (campaign?.attribute?.fundraising_target | number) ||
                  "Not available!"
              }}
            </div>
          </div>
        </div>
        <!-- <div class="organizer_inf">
          <div class="ngo_img">
            <img
              loading="lazy"
              src="./assets/images/icon/campaign-pay-met.svg"
              alt="organizer_img"
            />
          </div>
          <div class="ngo_title">
            <div class="title_">Payment Method</div>
            <div class="organizer_">Shohay Payment System</div>
          </div>
        </div> -->
        <div class="campaigns_timeline timeline">
          <div class="ngo_img">
            <img
              loading="lazy"
              src="./assets/images/icon/campaign-timeline.svg"
              alt="organizer_img"
            />
          </div>
          <div class="ngo_title">
            <div class="title_">Campaigns Timeline</div>
            <div class="organizer_">
              {{
                (campaign.attribute?.start_date | date : "dd MMM,yy") +
                  (campaign.attribute?.end_date
                    ? " - " +
                      (campaign.attribute?.end_date | date : "dd MMM,yy")
                    : "")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    }@else {
    <div>No campaigns available</div>
    }
  </div>
</div>

<dx-popup
  [visible]="showCampaignDetails"
  [width]="'1255px'"
  height="auto"
  [maxHeight]="'95vh'"
  [showCloseButton]="true"
  [showTitle]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="true"
  (onHiding)="handleClosePopup()"
>
  <div class="main_div_">
    <div *ngIf="showDonationSummary">
      <span style="color: #0054a6">Home > </span
      ><span class="font_14">{{ campaign?.title }}</span>
    </div>

    <div
      class="main_container mt-4 mb-4 d-flex"
      [ngStyle]="{
        'justify-content': showDonationSummary ? 'normal' : 'center'
      }"
    >
      <div class="left_side">
        <div
          class="img_slider"
          [ngStyle]="{
            'background-image': 'url(' + campaign?.cover_img_vdo?.url + ')'
          }"
        >
          <div class="yellow_title_div" *ngIf="campaign?.is_featured">
            <div class="yellow_title">Featured</div>
          </div>
        </div>
        <div class="organizer_container mt-4">
          <div class="organizer">
            <img
              loading="lazy"
              alt="prochesta"
              src="../../../../../assets/images/icon/prochesta_foundation_icon.svg "
            />
            <span class="organized_text">
              Organized by
              <span style="font-weight: 500">
                {{ campaign?.ngo_name }}
              </span> </span
            ><img
              loading="lazy"
              alt="verified"
              src="../../../../../assets/images/icon/verified_icon_green.svg"
            />
          </div>
          <div class="time">
            <img
              style="margin-right: 8px"
              loading="lazy"
              alt=""
              src="../../../../../assets/images/icon/time-01.svg"
            /><span>{{ campaign?.end_date ? dayLeft : "Ongoing" }}</span>
          </div>
        </div>

        <div style="margin-block: 32px">
          <div class="title">{{ campaign?.title }}</div>
          <div class="subtitle">{{ campaign?.subtitle }}</div>
        </div>

        <div class="our_story">Our Story:</div>
        <div id="campaign_story"></div>
        @if(!initialPopUp){
        <!-- Commented as per Mobashwir bhai's direction -->
        <!-- <div class="button_group">
            <button class="white-button-lg w-50">Donate Now</button>
            <button class="white-button-lg w-50">Share</button>
          </div> -->

        @if(showFundraisers){
        <hr class="line" />
        <!-- fundraising_team_container -->
        <div class="fundrising_member">
          <div class="fundrising_header">
            <span class="title">Fundraising Members</span>
            <button
              class="yellow-button-lg"
              (click)="joinAsFundraiserComponent.showHideSignUpPopup(true)"
            >
              <img
                class="icon"
                loading="lazy"
                style="margin-right: 8px"
                src="../../../../../assets/images/icon/fundraising_start.svg"
                alt="add"
                title="add"
              />Join As a Fundraiser
            </button>
          </div>

          <!-- Fundraising Teams List -->
          <app-fundraisers-list
            [campaign_id]="campaignId"
          ></app-fundraisers-list>
        </div>
        }
        <!-- fundraising_team_container end-->

        <hr class="line" />

        <!-- contact_container -->
        <div>
          <span class="contact_person_text">Contact Person</span>
          <div class="founder_child">
            <div class="founder_div">
              <div>
                <img
                  src="../../../../../assets/images/mobashwir_bhai.png"
                  alt="Profile Picture"
                  class="rounded-circle"
                />
              </div>
              <div class="founder_info">
                <div class="contact_details">
                  <span class="ceo_name">Mobashwir Hasan</span>
                  <span>Organizer</span>
                  <span>CEO</span>
                  <span>{{ campaign?.ngo_name }}</span>
                  <button class="white-button-md">Contact</button>
                </div>
              </div>
            </div>
            <div>
              <img
                src="../../../../../assets/images/icon/next_blue_icon.svg"
                alt="Profile Picture"
                loading="lazy"
              />
            </div>
            <div class="d-flex" style="gap: 16px">
              <div>
                <img
                  src="../../../../../assets/images/icon/prochesta_foundation_icon.svg"
                  alt="Profile Picture"
                  loading="lazy"
                  style="height: 90px; width: 90px"
                />
              </div>
              <div>
                <div
                  class="d-flex align-items-center"
                  style="margin-bottom: 16px"
                >
                  <span class="org_title">{{ campaign?.ngo_name }}</span>
                  <img
                    src="../../../../../assets/images/icon/verified_icon_green.svg"
                    alt="Profile Picture"
                    loading="lazy"
                    style="width: 24px; height: 24px"
                  />
                </div>
                <div class="contact_details">
                  <div>NGO</div>
                  <div>Bangladesh</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="line" />

        <!-- Announcement -->
        <app-announcement [id_campaign]="campaignId"></app-announcement>
        <!-- Announcement end-->

        }@else {
        <div class="foggy-div">
          <p class="foggy-text">........</p>
        </div>
        <p class="load_more cursor" (click)="load_more()">Load More Details</p>
        }

        <div class="button_super">
          <dx-button
            class="yellow-button-lg reject"
            (click)="rejectPopUp()"
            [disabled]="activeTabIndex === 2"
          >
            Reject
          </dx-button>
          <dx-button
            class="blue-button-lg accept"
            (click)="takeAction(requestId, approvalStatusEnum.ACCEPTED)"
            [disabled]="activeTabIndex === 1"
          >
            Approve Request
          </dx-button>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="rejectButtonClickd"
  [width]="'1010px'"
  [height]="'531px'"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
  [showTitle]="false"
  [dragEnabled]="false"
>
  <div class="feedback_container">
    <div class="feedback_title">Feedback</div>
    <div class="feedback_body">
      <div class="feedback_inf">
        Write down why Campaigns Application is rejected
        <span style="color: red">*</span>
      </div>
      <dx-text-area
        class="feedback_msg"
        [autoResizeEnabled]="true"
        [minHeight]="134"
        [maxHeight]="300"
        placeholder="e.g message body"
        [(ngModel)]="approvalUpdate.comments"
      >
        <dx-validator validationGroup="validationGrp">
          <dxi-validation-rule type="required" message="Message is required">
          </dxi-validation-rule>
          <dxi-validation-rule
            type="stringLength"
            [max]="200"
            [trim]="false"
            message="200 characters maximum"
          >
          </dxi-validation-rule
        ></dx-validator>
      </dx-text-area>
    </div>
    <div class="feedback_buttons">
      <dx-button class="yellow-button-md feed_back" (click)="backExecute()">
        Back
      </dx-button>
      <dx-button
        class="blue-button-md feed_send"
        (click)="rejectedAction(requestId, approvalStatusEnum.DECLINED)"
      >
        Send
      </dx-button>
    </div>
  </div>
</dx-popup>
