<div class="main_div">
  <div class="breadcrumb">
    <span class="donation_title"> Donation Management / </span>
    <span> All Donor </span>
  </div>

  <div class="header_text">All Donor</div>
  <div class="full_panel row">
    <div class="col-sm-12 col-lg-6 tab_div">
      <div
        class="tab"
        [class.active]="getActiveTab(1)"
        (click)="setActiveTab(1)"
      >
        <img
          [src]="
            '/assets/images/icon/' +
            (activeTab === 1
              ? 'dashboard_active_icon.svg'
              : 'dashboard_inactive_icon.svg')
          "
        />Dashboard
      </div>
      <div
        class="tab"
        [class.active]="getActiveTab(2)"
        (click)="setActiveTab(2)"
      >
        <img
          [src]="
            'assets/images/icon/' +
            (activeTab === 2
              ? 'lists_icon_active.svg'
              : 'lists_icon_inactive.svg')
          "
        />All Donor
      </div>
    </div>
    <div class="col-sm-12 col-lg-6 panel_right_side">
      <div class="date-container">
        <div class="date-label">Start date</div>
        <dx-date-box
          class="custom-date-box"
          type="date"
          width="200"
          [value]="startDate"
          displayFormat="MMM d yyyy"
          [inputAttr]="{ 'aria-label': 'Start Date' }"
          [(value)]="startDate"
          [max]="endDate"
        >
        </dx-date-box>
      </div>
      <div class="date-container">
        <div class="date-label">End date</div>
        <dx-date-box
          class="custom-date-box"
          width="200"
          type="date"
          [value]="endDate"
          displayFormat="MMM d yyyy"
          [inputAttr]="{ 'aria-label': 'End Date' }"
          [(value)]="endDate"
          [min]="startDate"
        >
        </dx-date-box>
      </div>
    </div>
  </div>

  @if (getActiveTab(1)){
  <div class="row mb_32">
    <div class="col-4">
      <div class="card">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Total Donation</div>
          <img src="/assets/images/icon/donation_1_icon.svg" />
        </div>
        <div class="number">৳ 6,11,00,000</div>
        <div class="header text-dark">
          <span class="growth" data-value="+3">+3.21% </span
          ><span>growth last year</span>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Donor this year</div>
          <img src="/assets/images/icon/donation_year_icon.svg" />
        </div>
        <div class="number">৳ 6,11,00,000</div>
        <div class="header text-dark">
          <span class="growth" data-value="+3">+3.21% </span
          ><span>growth last year</span>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Average Donations</div>
          <img src="/assets/images/icon/donate_avarage_icon.svg" />
        </div>
        <div class="number">৳ 6,11,00,000</div>
        <div class="header text-dark">
          <span class="growth" data-value="-3">-3% </span
          ><span>growth last year</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb_32">
    <div class="col-8">
      <div class="top_donor_div pr-0">
        <div class="d-flex justify-content-between mb_32">
          <div class="top_donor fw_500">Recently Donated</div>
          <div (click)="openRecentDonorList()">
            <img class="cursor" src="/assets/images/icon/maximize_icon.svg" />
          </div>
        </div>

        <div class="donor_div">
          <dx-data-grid
            class="chart_div data_grid"
            [dataSource]="donations"
            [showBorders]="false"
            [showColumnLines]="false"
            [showRowLines]="false"
            [allowColumnResizing]="true"
            columnResizingMode="widget"
            [focusedRowEnabled]="false"
            [rowAlternationEnabled]="false"
            rowTemplate="rowTemplateName"
            [wordWrapEnabled]="true"
            [columnAutoWidth]="true"
          >
            <dxi-column caption="Name" dataField="name"></dxi-column>
            <dxi-column caption="Campaign" dataField="campaign"> </dxi-column>
            <dxi-column caption="Date" dataField="Date"> </dxi-column>
            <dxi-column caption="Amount" alignment="center"> </dxi-column>

            <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
              <tr class="custom-row">
                <td>
                  <div class="left_side_property" style="margin: 12px">
                    <img
                      class="profile_img"
                      loading="lazy"
                      [src]="'../../../../../assets/images/mobashwir_bhai.png'"
                      alt="profile_pic"
                    />
                    <div class="name_amount">
                      <div class="name">{{ row?.data?.name }}</div>
                      <div class="amount">#1943736193749</div>
                    </div>
                  </div>
                  {{ row?.data?.created_at }}
                </td>
                <td class="campaign_name">
                  {{ row?.data?.campaign }}
                </td>
                <td>
                  <div class="date_div">{{ row?.data?.date }}</div>
                </td>
                <td>
                  <div class="amount_div">৳ {{ row?.data?.amount }}</div>
                </td>
              </tr>
            </tbody>
          </dx-data-grid>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="top_donor_div pr-0">
        <div class="d-flex justify-content-between mb_32">
          <div class="top_donor fw_500">Top Donor</div>
          <div (click)="openTopDonorList()">
            <img class="cursor" src="/assets/images/icon/maximize_icon.svg" />
          </div>
        </div>

        <div class="donor_div">
          <div class="loop_div_container">
            <div
              class="loop_div"
              *ngFor="let list of donorFiltering; let i = index"
            >
              <div class="fundraiser_card">
                <div class="left_side_property">
                  @if(i===0){
                  <img
                    class="trophy_img"
                    loading="lazy"
                    [src]="'../../../../../assets/images/icon/trophy 2.svg'"
                    alt="trophy"
                  />
                  }@else if(i===1){
                  <img
                    class="trophy_img"
                    loading="lazy"
                    [src]="'../../../../../assets/images/icon/trophy-2.svg'"
                    alt="trophy"
                  />
                  }@else if(i===2){
                  <img
                    class="trophy_img"
                    loading="lazy"
                    [src]="'../../../../../assets/images/icon/trophy-3.svg'"
                    alt="trophy"
                  />
                  }@else {
                  <div class="trophy_img_">{{ i + 1 }}</div>
                  }
                  <img
                    class="profile_img"
                    loading="lazy"
                    [src]="'../../../../../assets/images/mobashwir_bhai.png'"
                    alt="profile_pic"
                  />
                  <div class="name_amount">
                    <div class="name">{{ list.name }}</div>
                    <div class="amount">৳ {{ list.total_raised }}</div>
                  </div>
                </div>
                <div class="right_side_property">
                  <img
                    class="heart"
                    loading="lazy"
                    [src]="'../../../../../assets/images/icon/heart_hand.svg'"
                    alt="heart"
                  />
                  <div class="number_donor">({{ list.team_member }})</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  } @if(getActiveTab(2)) {
  <app-donor-list></app-donor-list>
  }
</div>

<dx-popup
  class="popUp"
  [wrapperAttr]="{ class: 'popUp' }"
  [visible]="showDonationPopUp"
  [maxWidth]="802"
  [maxHeight]="1142"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [showTitle]="true"
  [title]="'Top Donors'"
  [dragEnabled]="false"
  (onHidden)="closePopup()"
>
  <div class="popup_container">
    <div class="popup_div" *ngFor="let list of donation; let i = index">
      <div class="popup_card">
        <div class="left_side_property">
          @if(i===0){
          <img
            class="trophy_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/trophy 2.svg'"
            alt="trophy"
          />
          }@else if(i===1){
          <img
            class="trophy_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/trophy-2.svg'"
            alt="trophy"
          />
          }@else if(i===2){
          <img
            class="trophy_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/trophy-3.svg'"
            alt="trophy"
          />
          }@else {
          <div class="trophy_img_">{{ i + 1 }}</div>
          }
          <img
            class="profile_img"
            loading="lazy"
            [src]="'../../../../../assets/images/mobashwir_bhai.png'"
            alt="profile_pic"
          />
          <div class="name_amount">
            <div class="name">{{ list.name }}</div>
            <div class="amount_donor">৳ {{ list.total_raised }}</div>
          </div>
        </div>
        <div class="right_side_property">
          <img
            class="heart"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/heart_hand.svg'"
            alt="heart"
          />
          <div class="number_donor">({{ list.team_member }})</div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  class="recentDonationPopup"
  className="recentDonationPopup"
  [wrapperAttr]="{ class: 'recentDonationPopup' }"
  [visible]="showRecentDonationPopUp"
  [maxWidth]="1482"
  [maxHeight]="1142"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [showTitle]="true"
  [dragEnabled]="false"
  (onHidden)="closePopup()"
>
  <div class="popup_container">
    <div class="header_recent_donation">Recently Donated</div>

    <div class="recent_donation_data_grid">
      <dx-data-grid
        class="recently_donated_data_grid"
        [dataSource]="recentDonations"
        [showBorders]="false"
        [showColumnLines]="false"
        [showRowLines]="false"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [focusedRowEnabled]="false"
        [rowAlternationEnabled]="false"
        rowTemplate="rowRecentDonationName"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="true"
      >
        <dxi-column caption="Donor name" dataField="donorName"></dxi-column>
        <dxi-column caption="Campaign" dataField="campaign" alignment="center">
        </dxi-column>
        <dxi-column caption="Time and Date" dataField="Date" alignment="center">
        </dxi-column>
        <dxi-column caption="Payment Method" alignment="center"> </dxi-column>
        <dxi-column caption="Donation Amount" alignment="center"> </dxi-column>
        <dxi-column caption="Receipt" alignment="center"> </dxi-column>

        <tbody class="dx-row" *dxTemplate="let row of 'rowRecentDonationName'">
          <tr class="custom-row">
            <td>
              <div class="left_side_property" style="margin: 12px">
                <img
                  class="profile_img"
                  loading="lazy"
                  [src]="'../../../../../assets/images/mobashwir_bhai.png'"
                  alt="profile_pic"
                />
                <div class="name_amount">
                  <div class="name">{{ row?.data?.donorName }}</div>
                  <div class="amount">#1943736193749</div>
                </div>
              </div>
              {{ row?.data?.created_at }}
            </td>
            <td class="campaign_name">
              {{ row?.data?.campaign }}
            </td>
            <td>
              <div class="">
                <div class="date">{{ row?.data?.date }}</div>
                <div class="time">{{ row?.data?.time }}</div>
              </div>
            </td>
            <td>
              <div class="payment_div">{{ row?.data?.paymentMethod }}</div>
            </td>
            <td>
              <div class="amount_div">৳ {{ row?.data?.donationAmount }}</div>
            </td>
            <td>
              <div><a class="download_div cursor"> Download Receipt </a></div>
            </td>
          </tr>
        </tbody>
      </dx-data-grid>
    </div>
  </div>
</dx-popup>
