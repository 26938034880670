import { Component } from '@angular/core';
import { CreationTypeEnum } from 'src/app/shared/enum/creation-type.enum';
import { NgoService } from 'src/app/shared/services/ngo/ngo.service';

@Component({
  selector: 'app-all-ngo-list',
  templateUrl: './all-ngo-list.component.html',
  styleUrl: './all-ngo-list.component.scss',
})
export class AllNgoListComponent {
  public creationTypeEnum = CreationTypeEnum;
  public searchKeyword = '';
  public activeTab: number = 1;

  public mainList: any[] = [];
  public activeList: any[] = [];

  constructor(private readonly ngoService: NgoService) {}

  ngOnInit() {
    this.getAllRequests();
  }

  private getAllRequests() {
    this.activeList = [];

    this.ngoService.getAllVerifiedNgos().subscribe({
      next: (result) => {
        this.mainList = result;
        this.activeList = result;
      },
    });
  }

  public get activeDataSource(): any[] {
    if (!this.searchKeyword) {
      return this.activeList;
    } else {
      return this.activeList.filter((campaign) =>
        campaign.name.toLowerCase().includes(this.searchKeyword)
      );
    }
  }

  public onSearchKeywordChanged(event: any) {
    this.searchKeyword = event.event.target.value.toLowerCase();
  }
}
