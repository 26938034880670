<div class="main_div">
  <div class="breadcrumb">
    <span class="donation_title"> Campaign Management / </span>
    <span> All Campaigns </span>
  </div>

  <div class="header">
    <div class="header_text">
      <div class="donation_camp_text">All Campaigns</div>
    </div>
  </div>

  <!-- panel-title -->
  <div class="full_panel row d-flex justify-content-between align-items-center">
    <div class="col d-flex p-0">
      <dx-tab-panel
        (onSelectionChanged)="onTabChange($event)"
        [selectedIndex]="activeTabIndex"
        style="box-shadow: none"
        [swipeEnabled]="true"
      >
        <dxi-item
          title="All"
          [icon]="
            activeTabIndex === 0
              ? './assets/images/icon/lists-active.svg'
              : './assets/images/icon/lists.svg'
          "
        >
        </dxi-item>
        <dxi-item
          title="Suspended"
          [icon]="
            activeTabIndex === 1
              ? './assets/images/icon/suspended-active.svg'
              : './assets/images/icon/suspended.svg'
          "
          [disabled]="true"
        >
          <div id="suspended-tab" class="disabled-tab"></div>
        </dxi-item>
        <dxi-item
          title="Ended"
          [icon]="
            activeTabIndex === 2
              ? './assets/images/icon/bookmark-active.svg'
              : './assets/images/icon/ended.svg'
          "
          [disabled]="true"
        >
        </dxi-item>
      </dx-tab-panel>
    </div>
    <div class="col panel_right_side d-flex gap-2 justify-content-end ">
      <div
        class="search_box"
        >
        <!-- placeholder="Filter By keyword"
        [showClearButton]="true" -->
        <!-- [buttons]="[
          {
            name: 'searchCampaign',
            location: 'before',
            options: {
              icon: 'find',
              elementAttr: {
                class: 'searchForm'
              }
            }
          }
        ]" -->
        <!-- <dx-validator validationGroup="validationGrp">
          <dxi-validation-rule></dxi-validation-rule>
        </dx-validator> -->
        <dx-autocomplete
          class="search_box"
          [dataSource]="allCampaignTitles"
          valueExpr="title"
          [(value)]="searchText"
          placeholder="Find campaign"
          [searchExpr]="['title', 'subtitle']"
          [searchTimeout]="10"
          [showClearButton]="true"
          [wrapItemText]="true"
          (onEnterKey)="searchCampaign($event)"
          (onSelectionChanged)="selectCampaign($event)"
        >
          <dxi-button
            name="searchIcon"
            location="before"
            [options]="searchIcon"
          ></dxi-button>

          <div id="campaign_titles" *dxTemplate="let data of 'item'">
            <div style="font-size: 16px; font-weight: 500">
              {{ data.title }}
            </div>
            <div>{{ data.subtitle }}</div>
          </div>

          <dxi-button
            *ngIf="searchText?.length > 0"
            name="crossIcon"
            location="after"
            [options]="crossIcon"
          ></dxi-button>
        </dx-autocomplete>
      </div>
    </div>
  </div>
  <!-- panel-title-end -->

  <div class="panel_content_for_my_campaign">
    @if(campaignList.length > 0){
    <div
      class="campaign_list cursor"
      *ngFor="let campaign of campaignList"
      (click)="navigateToCampaignSummary(campaign)"
    >
      <div class="ngo_organizer_inf">
        <div class="ngo_inf">
          <div class="ngo_img">
            <img
              loading="lazy"
              [src]="
                campaign.ngo_img || './assets/images/icon/campaing_img.svg'
              "
              alt="ngo_img"
            />
          </div>
          <div class="ngo_title">
            <div class="NGO">NGO</div>
            <div class="title_">
              {{ campaign?.ngo_name }}
            </div>
          </div>
        </div>

        <!-- <div class="organizer_inf">
          <div class="ngo_img_org">
            <img
              loading="lazy"
              [src]="campaign.organizer_img"
              alt="organizer_img"
            />
          </div>
          <div class="ngo_title">
            <div class="title_">
              {{ campaign.organizer_name }}
            </div>
            <div class="organizer_">
              Organizer |
              {{ campaign.organizer_number }}
            </div>
          </div>
        </div> -->

        <div class="status_goal">
          <div class="status">
            Campaigns Status:

            <span *ngIf="activeTabIndex === 0" class="pending">
              <!-- {{ campaign.status }} -->
              Running
            </span>
            <span *ngIf="activeTabIndex === 1" class="accepted">
              <!-- {{ campaign.status }} -->
              Suspended
            </span>
            <span *ngIf="activeTabIndex === 2" class="rejected">
              <!-- {{ campaign.status }} -->
              Ended
            </span>
          </div>
          <div class="goal_donor">
            <div class="goal">
              Raised {{ campaign.total_fund_raised | number : "1.0-0" }} of goal
            </div>
            <div class="donor">
              <img
                src="./assets/images/icon/donate_icon_inactive.svg"
                alt="donor_icon"
              />
              {{ campaign.total_donors }}
            </div>
          </div>
        </div>
      </div>

      <hr class="hr" />

      <div class="ngo_organizer_inf">
        <div class="ngo_inf_">
          <div class="campaign_img">
            <img
              loading="lazy"
              [src]="campaign?.cover_img_vdo.url"
              alt="ngo_img"
              srcset=""
            />
          </div>
          <div class="ngo_title">
            <div class="NGO">
              <span class="pending">{{ campaign?.subtitle }}</span>
            </div>
            <div class="title_campaign">
              {{ campaign?.title }}
            </div>
          </div>
        </div>

        <div class="organizer_inf timeline_">
          <div class="ngo_img">
            <img
              loading="lazy"
              src="./assets/images/icon/campaign-target.svg"
              alt="organizer_img"
            />
          </div>
          <div class="ngo_title">
            <div class="title_">Donation Target</div>
            <div class="organizer_">
              ৳
              {{ campaign?.fundraising_target | number }}
            </div>
          </div>
        </div>
        <!-- <div class="organizer_inf">
          <div class="ngo_img">
            <img
              loading="lazy"
              src="./assets/images/icon/campaign-pay-met.svg"
              alt="organizer_img"
            />
          </div>
          <div class="ngo_title">
            <div class="title_">Payment Method</div>
            <div class="organizer_">Shohay Payment System</div>
          </div>
        </div> -->
        <div class="organizer_inf timeline">
          <div class="ngo_img">
            <img
              loading="lazy"
              src="./assets/images/icon/campaign-timeline.svg"
              alt="organizer_img"
            />
          </div>
          <div class="ngo_title">
            <div class="title_">Campaigns Timeline</div>
            <div class="organizer_">
              {{
                (campaign.created_at | date : "dd MMM,yy") +
                  (campaign.end_date
                    ? " - " + (campaign?.end_date | date : "dd MMM,yy")
                    : "")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="direction-button">
      <dx-button
        class="blue-button-md"
        (click)="getAllCampaigns(page + 1, 3)"
        [disabled]="isShowMoreDisabled"
        >Load More</dx-button
      >
    </div>
    }@else {
    <div>No campaigns available</div>
    }
  </div>
</div>
