<div class="main_div">
  <div class="mb-4">
    <span class="details_div" style="color: #0054a6">NGO/NPO Management / </span
    ><span class="details_div" style="color: black">All NGOs/NPOs</span>
  </div>

  <div class="header">
    <div class="header_text">
      <div class="donation_camp_text">All NGOs/NPOs</div>
    </div>
  </div>

  <!-- panel-title -->
  <div class="full_panel row">
    <div class="col-sm-12 col-lg-6 tab_div">
      <div class="tab" [class.active]="activeTab === 1">
        <img [src]="'/assets/images/icon/lists_icon_active.svg'" />All
      </div>
      <!-- <div
        class="tab"
        [class.active]="activeTab === 2"
        (click)="changeTab(2, approvalStatusEnum.ACCEPTED)"
      >
        <img
          [src]="
            'assets/images/icon/' +
            (activeTab === 2 ? 'approved_icon_active.svg' : 'approved_icon.svg')
          "
        />Suspend
      </div> -->
    </div>
    <div class="col-sm-12 col-lg-6 panel_right_side">
      <dx-text-box
        class="search_box"
        placeholder="Find NGO"
        [showClearButton]="true"
        (onInput)="onSearchKeywordChanged($event)"
        [buttons]="[
          {
            name: 'searchCampaign',
            location: 'before',
            options: {
              icon: 'find',
              elementAttr: {
                class: 'searchForm'
              }
            }
          }
        ]"
      >
      </dx-text-box>
    </div>
  </div>
  <!-- panel-title-end -->

  <!-- tab-panel-content -->
  <div class="panel_content_for_my_campaign">
    <div class="ngo_list">
      <div class="ngo_card" *ngFor="let ngo of activeDataSource; let i = index">
        <div class="card_1st_div">
          <div class="logo_div">
            <img [src]="ngo.logo_url?.url" alt="Not Available" />
          </div>
          <div class="card_2st_div">
            <div class="title">
              {{ ngo.name }}
              <img [src]="'/assets/images/icon/verified_icon_green.svg'" />
            </div>
            <div class="address_div">
              <div>
                <img
                  class="m-0"
                  [src]="'/assets/images/icon/location_icon.svg'"
                />
                {{ ngo.address || "Not Available!" }}
              </div>
              <div>
                <img [src]="'/assets/images/icon/web_icon.svg'" />{{
                  ngo.website || "Not Available!"
                }}
              </div>
            </div>
            <div class="details_div">{{ ngo.key_activities }}</div>
            <button
              class="blue-button-md"
              [routerLink]="['/admin/ngo-management/ngo-details']"
              [queryParams]="{ id: ngo?.id }"
            >
              View Profile
            </button>
          </div>
        </div>

        <div
          *ngIf="mainList.length === 0"
          class="No_form_container d-flex flex-column align-items-center"
          style="margin-top: 50px"
        >
          <img
            src="./assets/images/icon/donation_colorful_icon.svg"
            alt="donation-image"
            title="donations"
          />
          <span class="no_form_workspace">No Verified Ngos yet</span>
        </div>
      </div>
    </div>
  </div>
</div>
