<div class="main_div">
  <div class="header">
    <div class="header_text">
      <div class="donation_camp_text">Contact Requests</div>
    </div>
  </div>

  <!-- panel-title -->
  <div class="full_panel">
    <div class="col panel_right_side">
      <dx-text-box
        class="search_box"
        placeholder="Filter By keyword"
        [showClearButton]="true"
        (onInput)="onSearchKeywordChanged($event)"
        [buttons]="[
          {
            name: 'searchCampaign',
            location: 'before',
            options: {
              icon: 'find',
              elementAttr: {
                class: 'searchForm'
              }
            }
          }
        ]"
      >
      </dx-text-box>
    </div>
  </div>
  <!-- panel-title-end -->

  <!-- tab-panel-content -->
  <div class="panel_content_for_my_campaign">
    <div class="campaign_list">
      <div *ngFor="let contact of activeDataSource; let i = index">
        <div class="campaign_card" [title]="contact?.message">
          <div class="title_div">
            <div class="title_subtitle">
              <div class="title">{{ contact?.name }}</div>
              <span class="donation_number">
                Email:
                {{ contact?.email }}
              </span>
              <div class="subtitle" style="color: #939393">
                {{ contact?.organization }}
              </div>
            </div>
          </div>
          <div class="title_subtitle" style="width: 400px">
            <div class="status_child">
              <div class="status">
                Contact Type:
                <div class="status_text">{{ contact?.contact_type }}</div>
              </div>
            </div>
            <div class="donation_count">
              <span class="donation_number">
                Phone:
                {{ contact?.phone || "No Data" }}
              </span>
            </div>
            <div class="donation_count">
              <span class="donation_number">
                Date-Time:
                {{
                  contact?.date || contact?.created_at
                    | date : "EEEE, MMMM d, y, h:mm a"
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- tab-panel-content-end -->

  <div
    *ngIf="allRequests.length === 0"
    class="No_form_container d-flex flex-column align-items-center"
    style="margin-top: 50px"
  >
    <img
      src="./assets/images/icon/donation_colorful_icon.svg"
      alt="donation-image"
      title="donations"
    />
    <span class="no_form_workspace"
      >There is no contact requests right now.</span
    >
  </div>
</div>
