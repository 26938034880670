<div class="recent_donation_data_grid">
  <dx-data-grid
    class="donor_list_data_grid"
    [dataSource]="donors"
    [showBorders]="false"
    [showColumnLines]="false"
    [showRowLines]="false"
    [allowColumnResizing]="true"
    columnResizingMode="widget"
    [focusedRowEnabled]="false"
    [rowAlternationEnabled]="false"
    rowTemplate="rowDonationList"
    [wordWrapEnabled]="true"
    [columnAutoWidth]="true"
  >
    <dxo-paging [pageSize]="5" [pageIndex]="0"> </dxo-paging>
    <dxo-pager
      *ngIf="donors?.length > 5"
      [visible]="true"
      [showPageSizeSelector]="false"
      [allowedPageSizes]="[5]"
      [showInfo]="false"
      [showNavigationButtons]="true"
    >
    </dxo-pager>
    <dxi-column caption="Name" dataField="name" alignment="center"></dxi-column>
    <dxi-column caption="Team" alignment="center"> </dxi-column>
    <dxi-column
      caption="Total Donated Amount"
      dataField="total_donated_amount"
      alignment="center"
    >
    </dxi-column>
    <dxi-column
      caption="Last Donated Amount"
      dataField="last_donated_amount"
      alignment="center"
    >
    </dxi-column>
    <dxi-column
      caption="Last Donation Date"
      dataField="last_donation_Date"
      alignment="center"
    >
    </dxi-column>

    <tbody
      class="dx-donor-row"
      *dxTemplate="let row of 'rowDonationList'; let index = index"
    >
      <tr class="custom-row-donor">
        <td>
          <div class="donor-profile cursor">
            <div class="donor-number">1</div>
            <div class="profile-avatar">
              <img
                class="profile_img"
                loading="lazy"
                [src]="'../../../../../assets/images/mobashwir_bhai.png'"
                alt="profile_pic"
              />
            </div>
            <div class="profile-details">
              <div class="donor-name">Sabina Akhtar</div>
              <div class="donor-email">Sabinagmail.com</div>
            </div>
          </div>
        </td>
        <td>
          <div class="logo-container">
            <div
              class="logo"
              *ngFor="let logo of row?.data?.team; let i = index"
              [style.zIndex]="i"
              [style.right]="i === 1 ? '0' : i * 20 + '%'"
            >
              <img [src]="logo.logo" [alt]="logo.alt" />
            </div>
          </div>
        </td>
        <td>
          <div class="text-center">
            <div class="donor_amount">{{ row?.data?.totalDonatedAmount }}</div>
          </div>
        </td>
        <td>
          <div class="donor_amount">{{ row?.data?.lastDonatedAmount }}</div>
        </td>
        <td>
          <div class="date_time">{{ row?.data?.lastDonationDate }}</div>
        </td>
      </tr>
    </tbody>
  </dx-data-grid>
</div>
