<div class="main_div">
  <!-- <div class="header">
    <p style="font-size: 30px; margin-bottom: 0">Donation History</p>
  </div> -->

  <!-- <div class="payment-method-wrapper mb-4 pt-4">
    <h3 class="payment-method">Donation History</h3>
    <div class="search_bar" style="padding-right: 0px">
      <dx-text-box
        class="search_box"
        placeholder="Enter NGO’s name"
        stylingMode="filled"
        [showClearButton]="true"
      >
        <dxi-button
          name="searchIcon"
          location="before"
          [options]="searchIcon"
        ></dxi-button>

        <dxi-button
          class="search_button"
          name="today"
          location="after"
          [options]="searchButton"
        ></dxi-button>
      </dx-text-box>

      <div class="input_div">
        <p class="field_label">Sort By</p>
        <dx-select-box
          class="input_field"
          [dataSource]="['Alphabetically', 'Date wised', 'Time left']"
          placeholder="Alphabetically"
        >
        </dx-select-box>
      </div>
    </div>
  </div> -->

  <div class="datagrid_container">
    <dx-data-grid
      class="donation_list_datagrid"
      id="gridContainer"
      *ngIf="donationList.length"
      #gridContainer
      [dataSource]="donationList"
      [allowColumnReordering]="true"
      [allowColumnResizing]="true"
      [wordWrapEnabled]="true"
      [columnAutoWidth]="false"
      [rowAlternationEnabled]="true"
      rowTemplate="rowTemplateName"
      [showBorders]="true"
      [summary]="{
        totalItems: [
          {
            column: 'amount',
            summaryType: 'sum',
            displayFormat: ' ৳{0}',
            alignment: 'center',
            valueFormat: {
                  precision: 2
                },
            cssClass: 'tk_amount',
          },
          
          {
            column: 'amount',
            alignment: 'right',
            color: 'red',
            displayFormat: 'Total Donated Amount',
            showInColumn: 'Campaign',
            cssClass: 'total_amount',
          }
        ]
      }"
    >
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <!-- *ngIf="donationList?.length > 15" -->
      <dxo-paging [pageSize]="15"></dxo-paging>
      <!-- <dxo-search-panel [visible]="true" [width]="240" placeholder="Search...">
      </dxo-search-panel> -->
      <dxo-pager
        *ngIf="donationList?.length > 15"
        [visible]="true"
        [showPageSizeSelector]="false"
        [allowedPageSizes]="[15]"
        [showInfo]="true"
        [infoText]="'Page {0} of ' + (total_size + 15) / 15 | number"
        [showNavigationButtons]="true"
      >
      </dxo-pager>
      <dxo-search-panel
        [visible]="true"
        alignment="left"
        stylingMode="filled"
        class="search-panel"
        placeholder="Search Campaign or ngo"
      >
      </dxo-search-panel>
      <dxo-grouping></dxo-grouping>

      <dxi-column
        caption="Date"
        dataField="created_at"
        alignment="left"
      ></dxi-column>
      <dxi-column
        caption="NGO"
        dataField="ngo_name"
        alignment="left"
      ></dxi-column>
      <dxi-column
        caption="Campaign"
        dataField="campaign_title"
        alignment="left"
      ></dxi-column>
      <dxi-column
        caption="Amount"
        dataField="amount"
        alignment="center"
      ></dxi-column>
      <dxi-column caption="Status" alignment="Left"></dxi-column>
      <dxi-column caption="Change Status" alignment="left"> </dxi-column>
      <!-- <dxi-column caption="Share" alignment="left"></dxi-column> -->

      <dxo-toolbar class="row">
        <dxi-item name="searchPanel" location="after"></dxi-item>
        <dxi-item location="before">
          <h3 class="payment-method">Donation History</h3>
        </dxi-item>
      </dxo-toolbar>

      <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
        <tr class="custom-row">
          <td style="font-weight: 400; padding-left: 12px">
            {{ row?.data?.created_at }}
          </td>
          <td class="campaign_name">
            {{ row?.data?.ngo_name || "Not available" }}
          </td>
          <td class="campaign_name cursor">
            {{ row?.data?.campaign_title || "Not available" }}
          </td>
          <td style="color: #272727; text-align: center">
            <div class="amount">
              ৳{{ row?.data?.amount | number : "1.0-0" }}
            </div>
          </td>
          <td
            [ngClass]="{
              'state-received':
                (row?.data?.status || 'RECEIVED') === 'RECEIVED',
              'state-disbursed': row?.data?.status === 'DISBURSED'
            }"
          >
            <ul class="state_list" style="margin: 0">
              <li class="state capitalize-first-letter">
                {{ row?.data?.status || "RECEIVED" }}
              </li>
            </ul>
          </td>
          <td>
            <dx-select-box
              [dataSource]="statusOptions"
              placeholder="Change Status"
              (onValueChanged)="onStatusChange($event, row?.data)"
              [showClearButton]="true"
            ></dx-select-box>
          </td>
          <!-- <td>
            <div
              style="padding-left: 12px; color: #dc6803"
              *ngIf="row?.data?.status != 'DISBURSED'"
            >
              <img
                loading="lazy"
                alt="warning"
                src="../../../../assets/images/icon/warning_fill_white_icon.svg"
              />
              Photos/Videos will be available after disbursement
            </div>
            <div>
              <button
                class="yellow-button-sm radius_8"
                *ngIf="row?.data?.status === 'DISBURSED'"
              >
                Share Photos/Videos
              </button>
            </div>
          </td> -->
        </tr>
      </tbody>
    </dx-data-grid>
    <div class="pb-4"></div>
  </div>
</div>
