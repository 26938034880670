import { ChangeDetectorRef, Component } from '@angular/core';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { FileService } from 'src/app/shared/services/file/file.service';
import { CampaignModel } from 'src/app/shared/model/campaign.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { CampaignsService } from 'src/app/shared/services/campaign/campaigns.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, forkJoin, map, Observable, of, throwError } from 'rxjs';
import { IFile } from 'src/app/shared/interface/file.interface';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrl: './create-campaign.component.scss',
})
export class CreateCampaignComponent {
  public isEditMode = false;
  public popupVisible = false;

  public appealImgFile: File; // For file saving
  public sliderImgFiles: File[] = [];
  public appealImgUrl: string; // For showing files
  public sliderImgUrls: string[] = [];
  public campaignAppealImgFile: IFile; // For file_id
  public campaignSliderImgFiles: IFile[];

  private filesToDelete: string[] = [];

  public campaignId: string;
  public campaignModel: CampaignModel = new CampaignModel();

  constructor(
    private readonly router: Router,
    private readonly fileService: FileService,
    private readonly spinner: NgxSpinnerService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly campaignService: CampaignsService,
    private readonly utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.campaignId = params['id'];
      if (this.campaignId) {
        this.isEditMode = true;
        this.getCampaignById();
      }
    });
  }

  private getCampaignById() {
    this.spinner.show();
    this.campaignService.campaignGetById(this.campaignId).subscribe({
      next: (result) => {
        this.spinner.hide();
        this.campaignModel = new CampaignModel(result);

        this.campaignAppealImgFile = JSON.parse(result?.appeal_img_url);
        this.campaignSliderImgFiles = JSON.parse(result?.img_url);

        this.appealImgUrl = this.campaignAppealImgFile.url;
      },
      error: (err) => {
        this.spinner.hide();
      },
    });
  }

  private checkFilesValidation(): boolean {
    let flag: boolean = true;
    let title: string;
    let message: string;
    if (!this.appealImgUrl) {
      title = 'Banner image is required';
      message = 'Please upload a banner image';
      flag = false;
    }
    if (
      this.sliderImgUrls.length === 0 &&
      this.campaignSliderImgFiles.length === 0
    ) {
      title = 'At least one slider image is required';
      message = 'Please upload at least one slider image';
      flag = false;
    }
    if (
      !this.appealImgUrl &&
      this.sliderImgUrls.length === 0 &&
      this.campaignSliderImgFiles.length === 0
    ) {
      title = 'Please upload required images';
      message = 'Banner image and at least one slider image is required';
    }

    if (!flag) {
      this.utilitiesService.showSwal('warning', title, message);
    }
    return flag;
  }

  public submit() {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid && this.checkFilesValidation()) {
      this.deleteFiles();

      this.spinner.show();
      this.uploadFiles().subscribe({
        next: (result: any) => {
          this.modifyCampaignBody(result);

          const apiRequest$ = this.isEditMode
            ? this.campaignService.updateCampaign(
                this.campaignId,
                this.campaignModel
              )
            : this.campaignService.createCampaign(this.campaignModel);

          apiRequest$.subscribe({
            next: (res) => {
              this.spinner.hide();
              this.campaignId = this.isEditMode ? this.campaignId : res.id;
              this.popupVisible = true;
            },
            error: (err) => {
              this.spinner.hide();
              this.utilitiesService.showSwal(
                'error',
                'Error ' + this.isEditMode
                  ? 'updating'
                  : 'creating new' + ' campaign.'
              );
            },
          });
        },
        error: (err) => {
          this.spinner.hide();
          this.utilitiesService.showSwal('error', 'Files upload failed.');
        },
      });
    }
  }

  private deleteFiles(): void {
    const deleteRequests = this.filesToDelete.map((fileId) =>
      this.fileService.deleteFile(fileId).pipe(
        catchError((error) => {
          return of({ success: false, fileId: fileId });
        })
      )
    );

    forkJoin(deleteRequests).subscribe({
      next: (results: any) => {
        this.utilitiesService.showSpinner(false);
        const failedDeletes = results.filter(
          (result) => result && result.success === false
        );
      },
    });
  }

  private uploadFiles(): Observable<{
    appealResult: any;
    sliderResults: any[];
  }> {
    const appealFileUpload$ = this.appealImgFile
      ? this.fileService.uploadSingleFile(this.appealImgFile)
      : of(this.campaignAppealImgFile);

    const sliderFilesUpload$ =
      this.sliderImgFiles.length > 0
        ? this.fileService.uploadFiles(this.sliderImgFiles)
        : of([]);

    return forkJoin([appealFileUpload$, sliderFilesUpload$]).pipe(
      map(([appealResult, sliderResults]) => {
        return { appealResult, sliderResults };
      })
    );
  }

  private modifyCampaignBody(result: any): void {
    this.campaignModel.title = this.campaignModel.campaign_title;

    this.campaignModel.organizer = 'BMQA';
    this.campaignModel.start_date = new Date(
      this.campaignModel.start_date
    ).toISOString();
    this.campaignModel.end_date = new Date(
      this.campaignModel.end_date
    ).toISOString();

    this.campaignModel.appeal_img_url = JSON.stringify(result.appealResult[0]);
    this.campaignModel.img_url = JSON.stringify([
      ...(this.campaignSliderImgFiles || []),
      ...result.sliderResults,
    ]);
  }

  public onFileChange(event: any, source: 'bannerFile' | 'sliderFiles') {
    if (source === 'bannerFile') {
      const selectedFile: File = event.target.files[0];
      this.appealImgFile = selectedFile;
      this.appealImgUrl = URL.createObjectURL(selectedFile);
    } else {
      const selectedFile: File[] = Array.from(event.target.files);
      selectedFile.forEach((file) => {
        this.sliderImgFiles.push(file);
        this.sliderImgUrls.push(URL.createObjectURL(file));
      });
    }
  }

  public async deleteImgFile(
    fileUrl: string,
    varName: string,
    index: number,
    fileId: string
  ) {
    if (index >= 0) {
      this[varName] = this[varName].filter((_, i) => i !== index);
      if (varName === 'sliderImgUrls') {
        this.sliderImgFiles = this.sliderImgFiles.filter((_, i) => i !== index);
      }
    } else {
      this.appealImgFile = null;
      this.appealImgUrl = null;
    }

    if (!(fileUrl.startsWith('blob'))) {
      this.filesToDelete.push(fileId);
    }
  }

  public onSuccessfulSubmit(route: string, queryParams: boolean): void {
    this.popupVisible = false;
    if (queryParams) {
      this.router.navigate([route], { queryParams: { id: this.campaignId } });
    } else {
      this.router.navigate([route]);
    }
  }

  public triggerFileInput(inputId: string): void {
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  public onDropSuccess(event: DragEvent, fileType: 'bannerFile' | 'sliderFiles') {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    let errorFileType = false;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!this.isValidFileType(file)) {
          errorFileType = true;
          this.utilitiesService.showSwal(
            'warning',
            'Please select jpg, jpeg or png file'
          );
          return;
        }
      }
      if (!errorFileType) {
        this.onFileChange({target: {files: files}}, fileType)
      }
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    const items = event.dataTransfer?.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file && !this.isValidFileType(file)) {
            event.dataTransfer.dropEffect = 'none';
            return;
          }
        }
      }
      event.dataTransfer.dropEffect = 'copy';
    }
  }

  private isValidFileType(file: File): boolean {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    return allowedTypes.includes(file.type);
  }

  public validateWordCount(e: any): boolean {
    const wordCount = e.value ? e.value.trim().split(/\s+/).length : 0;
    return wordCount <= 200;
  }
}
