<div class="main_div">
  <div class="mb_32">
    <span class="header">NGO/NPO Management / All NGOs/NPOs / </span
    ><span class="header_ngo">{{ ngoDetails?.name }}</span>
  </div>

  <div class="top_header">
    <div class="d-flex gap-2 align-items-center">
      <span class="ngo_name">{{ ngoDetails?.name }}</span>
      <span
        *ngIf="ngoDetails"
        class="ngo_status"
        [class.active]="ngoDetails?.is_active"
        >{{ ngoDetails?.is_active ? "Active" : "Inactive" }}</span
      >
    </div>
    <div class="d-flex gap-4">
      <button class="blue-button-lg">
        <img src="/assets/images/icon/suspend_yellow_icon.svg" />
        Suspend
      </button>
      <img src="/assets/images/icon/more_vertical_icon.svg" />
    </div>
  </div>

  <div
    class="tab_div"
    [ngStyle]="{
      'justify-content': getActiveTab(1) ? 'space-between' : 'flex-end'
    }"
  >
    <dx-date-range-box
      *ngIf="getActiveTab(1)"
      class="time_line"
      [startDate]="oneYearAgo"
      [endDate]="today"
      labelMode="floating"
      [min]="oneYearAgo"
      [max]="today"
      displayFormat="dd MMM, yyyy"
      (onValueChanged)="filterDonations($event)"
    ></dx-date-range-box>
    <div class="tab_button_div">
      <div
        class="tab"
        [class.active]="getActiveTab(1)"
        (click)="setActiveTab(1)"
      >
        <img
          [src]="
            getActiveTab(1)
              ? '/assets/images/icon/dashboard_active_icon.svg'
              : '/assets/images/icon/dashboard_inactive_icon.svg'
          "
        />
        <div>Dashboard</div>
      </div>
      <div
        class="tab"
        [class.active]="getActiveTab(2)"
        (click)="setActiveTab(2)"
      >
        <img
          [src]="
            getActiveTab(2)
              ? '/assets/images/icon/info_active_icon.svg'
              : '/assets/images/icon/info_inactive_icon.svg'
          "
        />
        <div>NGO Info</div>
      </div>
      <div
        class="tab"
        [class.active]="getActiveTab(3)"
        (click)="setActiveTab(3)"
      >
        <img
          [src]="
            getActiveTab(3)
              ? '/assets/images/icon/charity_active_icon.svg'
              : '/assets/images/icon/charity_inactive_icon.svg'
          "
        />
        <div>Projects</div>
      </div>
    </div>
  </div>

  @if (getActiveTab(1)) {
  <div class="row mb_32">
    <div class="col-6">
      <div class="card">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Total Donation</div>
          <img src="/assets/images/icon/donation_1_icon.svg" />
        </div>
        <div class="number">
          ৳{{ ngoDetails?.total_donation | number : "1.0-0" }}
        </div>
        <!-- <div class="header text-dark">
          <span class="growth" data-value="+3">+3.21% </span
          ><span>growth last year</span>
        </div> -->
      </div>
    </div>
    <!-- <div class="col-4">
      <div class="card">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Donation this year</div>
          <img src="/assets/images/icon/donation_2_icon.svg" />
        </div>
        <div class="number">৳ 6,11,00,000</div>
        <div class="header text-dark">
          <span class="growth" data-value="+3">+3.21% </span
          ><span>growth last year</span>
        </div>
      </div>
    </div> -->
    <div class="col-6">
      <div class="card">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Total Donor</div>
          <img src="/assets/images/icon/donor_icon.svg" />
        </div>
        <div class="number">{{ ngoDetails?.total_donor | number }}</div>
        <!-- <div class="header text-dark">
          <span class="growth" data-value="-3">-3% </span
          ><span>growth last year</span>
        </div> -->
      </div>
    </div>
  </div>

  <div class="row mb_32">
    <div class="h_541 col-8">
      <dx-chart id="chart" class="chart_div" [dataSource]="donations">
        <dxo-title text="Donation Over Time" horizontalAlignment="left">
          <dxo-font family="Inter" size="24" weight="500"></dxo-font>
        </dxo-title>
        <dxo-zoom-and-pan argumentAxis="both"></dxo-zoom-and-pan>
        <dxo-scroll-bar [visible]="true"></dxo-scroll-bar>
        <dxo-animation
          easing="linear"
          [duration]="500"
          [maxPointCountSupported]="300"
        >
        </dxo-animation>
        <dxo-legend
          verticalAlignment="top"
          horizontalAlignment="right"
          position="inside"
          backgroundColor="none"
        >
        </dxo-legend
        ><dxi-series
          argumentField="created_at"
          valueField="amount"
          name="Donations"
          type="bar"
          color="#0054a6"
          barWidth="28"
          cornerRadius="0px"
        >
        </dxi-series>
        <dxo-argument-axis
          [visualRange]="{
            length: 10
          }"
        >
          <dxo-label [customizeText]="customizeArg"></dxo-label>
        </dxo-argument-axis>
        <dxo-common-series-settings
          argumentField="created_at"
          type="bar"
          hoverMode="allArgumentPoints"
          selectionMode="allArgumentPoints"
        >
          <dxo-label
            [visible]="true"
            [format]="{
              type: 'fixedPoint',
              precision: 0
            }"
          >
          </dxo-label>
        </dxo-common-series-settings>
      </dx-chart>
    </div>

    <div class="h_541 col-4">
      <div class="chart_div pr-0">
        <div class="d-flex justify-content-between mb_32">
          <div class="ngo_name fw_500">Projects</div>
          <img class="cursor" src="/assets/images/icon/maximize_icon.svg" />
        </div>

        <div class="project_div">
          <div class="project_item" *ngFor="let project of projects">
            <img src="/assets/images/icon/project_icon_colorful_round.svg" />
            <div class="flex-grow-1">
              <div class="total_donation fw-bolder">
                {{ project?.name }}
              </div>
              <!-- <div class="header">WASH</div> -->
            </div>

            <div>
              <div class="header">Status</div>
              <div class="header text-dark">
                {{ project?.is_active ? "In Progress" : "Stopped" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb_32">
    <div class="h_541 col-8">
      <dx-data-grid
        class="chart_div data_grid"
        [dataSource]="donations"
        [showBorders]="false"
        [showColumnLines]="false"
        [showRowLines]="false"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [focusedRowEnabled]="false"
        [rowAlternationEnabled]="false"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="true"
        rowTemplate="rowTemplateName"
        [paging]="{ enabled: false }"
      >
        <dxo-toolbar>
          <dxi-item location="before">
            <div class="ngo_name fw_500">Donation History</div>
          </dxi-item>
          <dxi-item location="after">
            <img class="cursor" src="/assets/images/icon/maximize_icon.svg" />
          </dxi-item>
        </dxo-toolbar>
        <dxi-column dataField="name" dataType="string"> </dxi-column>
        <dxi-column dataField="campaign_title" dataType="string"> </dxi-column>
        <dxi-column dataField="created_at" dataType="string" [width]="150">
        </dxi-column>
        <dxi-column dataField="amount" dataType="string" [width]="120">
        </dxi-column>

        <tbody
          class="dx-row"
          [ngClass]="{ 'dx-row-alt': row.rowIndex % 2 }"
          *dxTemplate="let row of 'rowTemplateName'"
        >
          <tr class="custom-row">
            <td class="fw-bolder">{{ row?.data?.name }}</td>
            <td style="color: #0054a6">{{ row?.data?.campaign_title }}</td>
            <td class="text-dark">
              {{ row?.data?.created_at | date : "dd MMM yyyy" }}
            </td>
            <td class="fw-bolder text-dark">
              ৳{{ row?.data?.amount | number : "1.0-0" }}
            </td>
          </tr>
        </tbody>
      </dx-data-grid>
    </div>

    <div class="h_541 col-4">
      <div class="chart_div" style="padding-right: 0px">
        <div
          class="d-flex justify-content-between mb_32"
          style="padding-right: 32px"
        >
          <div class="ngo_name fw_500">Donation Campaigns</div>
          <img class="cursor" src="/assets/images/icon/maximize_icon.svg" />
        </div>

        <div
          id="scrollableDiv"
          class="project_div"
          [ngStyle]="{ 'padding-right': checkScroll() ? '16px' : '32px' }"
        >
          <div class="donation_item" *ngFor="let campaign of campaigns">
            <div class="d-flex align-items-center">
              <img
                class="campaign_img"
                [src]="campaign?.cover_img_vdo"
                alt="Campaign cover"
              />
              <div class="flex-grow-1">
                <div class="total_donation fw-bolder">
                  {{ campaign?.title }}
                </div>
              </div>
            </div>
            <dx-progress-bar
              [min]="0"
              [max]="campaign?.fundraising_target"
              [value]="campaign?.total_fund_raised"
              [showStatus]="false"
            ></dx-progress-bar>
            <div class="d-flex justify-content-between">
              <div class="total_donation">
                <span
                  >৳
                  {{ campaign?.total_fund_raised | number : "1.0-0" }} / </span
                ><span style="color: #bebebe">{{
                  campaign?.fundraising_target | number : "1.0-0"
                }}</span>
              </div>
              <div class="total_donation fw_700">
                {{
                  (campaign?.total_fund_raised / campaign?.fundraising_target) *
                    100 | number : "1.0-0"
                }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  } @if(getActiveTab(2)) {
  <app-ngo-info [ngo_details]="ngoDetails"></app-ngo-info>
  } @if(getActiveTab(3)) {
  <app-ngo-projects [ngo_id]="ngoId"></app-ngo-projects>
  }
</div>
