import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgoService } from 'src/app/shared/services/ngo/ngo.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-ngo-details',
  templateUrl: './ngo-details.component.html',
  styleUrl: './ngo-details.component.scss',
  providers: [DatePipe],
})
export class NgoDetailsComponent implements OnInit {
  public utilitiesService = UtilitiesService;

  public activeTab: number = 1;
  public selectedTimeLine = 'One Week';

  public today: Date = new Date();
  public oneYearAgo: Date = new Date(
    this.today.getFullYear() - 1,
    this.today.getMonth(),
    this.today.getDate()
  );

  public ngoId: string;
  public ngoDetails: any;

  public projects: any[] = [];
  public campaigns: any[] = [];
  private donationPrivate: any[] = [];
  public donations: any[] = [];

  constructor(
    private datePipe: DatePipe,
    private readonly route: ActivatedRoute,
    private readonly ngoService: NgoService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.ngoId = params['id'];

      if (this.ngoId) {
        this.getNgoDetails();
      }
    });
  }

  private getNgoDetails(): void {
    this.ngoService
      .getNgoDetails(this.ngoId, this.oneYearAgo, this.today)
      .subscribe((result: any) => {
        const { ngo_info, total_donation, total_donor } = result;
        this.ngoDetails = { ...ngo_info, total_donation, total_donor };
        this.projects = result.projects;

        result.donation_history.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
        this.donations = result.donation_history;
        this.donationPrivate = result.donation_history;

        result.campaigns.forEach((cmp) => {
          cmp.cover_img_vdo = UtilitiesService.getUrl(cmp?.cover_img_vdo);
        });
        this.campaigns = result.campaigns;
      });
  }

  public getActiveTab(tab: number): boolean {
    return tab === this.activeTab;
  }

  public setActiveTab(tab: number): void {
    this.activeTab = tab;
  }

  public changeTimeLine(e: any): void {
    this.selectedTimeLine = e.itemData.text;
  }

  public filterDonations(e: any): void {
    this.donations = this.donationPrivate.filter(
      (donation) =>
        new Date(donation?.created_at) >= new Date(e?.value[0]) &&
        new Date(donation?.created_at) <= new Date(e?.value[1])
    );
  }

  public customizeArg(e: any): string {
    return new DatePipe('en-US').transform(e.valueText, 'dd MMM,yy');
  }
  public checkScroll(): boolean {
    const scrollableDiv = document.getElementById('scrollableDiv');
    return scrollableDiv.scrollHeight > scrollableDiv.clientHeight;
  }
}
