import { Component } from '@angular/core';

@Component({
  selector: 'app-s-admin-campaign-details',
  templateUrl: './s-admin-campaign-details.component.html',
  styleUrl: './s-admin-campaign-details.component.scss'
})
export class SAdminCampaignDetailsComponent {

}
