import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, forkJoin, of } from 'rxjs';
import { CampaignStatusEnum } from 'src/app/shared/enum/campaign-status.enum';
import { CampaignsService } from 'src/app/shared/services/campaign/campaigns.service';
import { FileService } from 'src/app/shared/services/file/file.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrl: './campaign-list.component.scss',
})
export class CampaignListComponent {
  public allCampaign: any = [];
  public recentCampaign: any = [];
  public favCampaign: any = [];
  private tabIcons = [
    {
      inactive: '../../../../../assets/images/icon/schedule_icon_active.svg',
      active: '../../../../../assets/images/icon/schedule_icon.svg',
    }, // Recent
    {
      inactive: '../../../../../assets/images/icon/description_inactive.svg',
      active: '../../../../../assets/images/icon/description_blue.svg',
    }, // My campaigns
    {
      inactive: '../../../../../assets/images/icon/kid_star_icon.svg',
      active: '../../../../../assets/images/icon/kid_star_icon_active.svg',
    }, // Favorite
  ];

  public activeTab = 0;
  public activeTabIndex = 0;
  public searchKeyword = '';

  constructor(
    public readonly router: Router,
    private readonly fileService: FileService,
    public readonly spinner: NgxSpinnerService,
    public readonly campaignService: CampaignsService,
    public readonly utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.getAllCampaign();
  }

  private getAllCampaign() {
    this.allCampaign = [];
    this.spinner.show();
    this.campaignService.getAllCampaigns(false).subscribe({
      next: (result) => {
        this.spinner.hide();
        this.allCampaign = result;
        this.recentCampaign = result;
      },
      error: (err) => {
        this.spinner.hide();
      },
    });
  }

  private async stopCampaign(campaignId: string) {
    const response = await this.confirmAction(
      'You want to stop the campaign?',
      'Yes, Stop'
    );
    if (response.isConfirmed) {
      const currentDate = new Date().toISOString();
      this.spinner.show();

      this.campaignService.stopCampaign(campaignId, currentDate).subscribe({
        next: (res) => {
          this.spinner.hide();
          if (res?.is_updated) {
            this.utilitiesService.showSwal(
              'success',
              'Campaign stopped successfully!'
            );
            this.getAllCampaign();
          }
        },
        error: (err) => {
          this.spinner.hide();
          this.utilitiesService.showSwal(
            'error',
            'Error stopping the campaign'
          );
        },
      });
    }
  }

  private async startCampaign(campaignId: string) {
    const response = await this.confirmAction(
      'You want to start the campaign?',
      'Yes, Start'
    );
    if (response.isConfirmed) {
      const currentDate = new Date().toISOString();
      this.spinner.show();

      this.campaignService.startCampaign(campaignId, currentDate).subscribe({
        next: (res) => {
          this.spinner.hide();
          if (res?.is_updated) {
            this.utilitiesService.showSwal(
              'success',
              'Campaign started successfully!'
            );
            this.getAllCampaign();
          }
        },
        error: (err) => {
          this.spinner.hide();
          this.utilitiesService.showSwal(
            'error',
            'Error starting the campaign'
          );
        },
      });
    }
  }

  private async deleteCampaign(campaign: any) {
    if (campaign.status === CampaignStatusEnum.RUNNING) {
      this.utilitiesService.showSwal(
        'error',
        'Stop the campaign first',
        'You need to stop the campaign in order to delete'
      );
    } else {
      const response = await this.confirmAction(
        'You want to delete the campaign?',
        'Yes, Delete'
      );
      if (response.isConfirmed) {
        this.spinner.show();

        this.campaignService.deleteCampaign(campaign.id).subscribe({
          next: (res) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'success',
              'Campaign deleted successfully!'
            );
            this.allCampaign = this.allCampaign.filter(
              (item) => item.id !== campaign.id
            );
            this.recentCampaign = this.recentCampaign.filter(
              (item) => item.id !== campaign.id
            );
            this.deleteFiles(campaign);
          },
          error: (err) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'error',
              'Error deleting the campaign'
            );
          },
        });
      }
    }
  }

  private deleteFiles(campaign: any): void {
    let imgFileList = [campaign.appeal_img_url, ...campaign.img_url];
    this.utilitiesService.showSpinner(true);

    const deleteRequests = imgFileList.map((file) =>
      this.fileService.deleteFile(file.file_id).pipe(
        catchError((error) => {
          return of({ success: false, fileId: file.file_id });
        })
      )
    );

    forkJoin(deleteRequests).subscribe({
      next: (results: any) => {
        this.utilitiesService.showSpinner(false);
        const failedDeletes = results.filter(
          (result) => result && result.success === false
        );

        if (failedDeletes.length > 0) {
          this.utilitiesService.showSwal(
            'error',
            'Failed to delete files.',
            `File id: ${failedDeletes.map((f) => f.fileId)}`
          );
        }
      },
      error: (err) => {
        this.utilitiesService.showSpinner(false);
        this.utilitiesService.showSwal('error', 'Failed to delete files.');
      },
    });
  }

  public buttonClick(e: any, campaign) {
    switch (e.itemData.text) {
      case 'Stop Campaign':
        this.stopCampaign(campaign.id);
        break;
      case 'Start Campaign':
        this.startCampaign(campaign.id);
        break;
      case 'Edit':
        this.router.navigate(['admin/campaign/edit'], {
          queryParams: { id: campaign.id },
        });
        break;
      case 'Delete':
        this.deleteCampaign(campaign);
        break;
      case 'Donations':
        this.router.navigate(['admin/donations'], {
          queryParams: {
            id: campaign?.id,
          },
        });
        break;
      case 'Share':
        break;
      default:
        break;
    }
  }

  public get activeDataSource() {
    const filterDataSource = this.getCurrentDataSource();
    return this.filterDataSource(filterDataSource);
  }

  public getCurrentDataSource() {
    switch (this.activeTab) {
      case 0:
        return this.recentCampaign;
      case 1:
        return this.allCampaign;
      case 2:
        return this.favCampaign;
      default:
        return [];
    }
  }

  private filterDataSource(dataSource: any[]) {
    if (!this.searchKeyword) {
      return dataSource;
    }
    const keyword = this.searchKeyword.toLowerCase();
    return dataSource.filter((campaign) =>
      campaign.campaign_title.toLowerCase().includes(keyword)
    );
  }

  public onTabChange(event: any) {
    this.activeTab = event.component.option('selectedIndex');
    this.activeTabIndex = event.itemIndex;
  }

  public getIcon(index: number): string {
    return this.activeTab === index
      ? this.tabIcons[index].active
      : this.tabIcons[index].inactive;
  }

  public onSearchKeywordChanged(event: any) {
    this.searchKeyword = event.event.target.value;
  }

  public formatDate(date: string): string {
    return this.utilitiesService.formattedDateddMMyyyy(
      date,
      'dd-mmm-yyyy',
      ' '
    );
  }

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }
}
