import { ProjectService } from 'src/app/shared/services/project/project.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ngo-projects',
  templateUrl: './ngo-projects.component.html',
  styleUrl: './ngo-projects.component.scss',
})
export class NgoProjectsComponent implements OnInit {
  @Input() ngo_id: string;

  public projects: any[] = [];

  constructor(private readonly projectService: ProjectService) {}

  ngOnInit(): void {
    if(this.ngo_id) {
      this.getProjects();
    }
  }

  private getProjects(): void {
    this.projectService
      .getProjectsByNgoId(this.ngo_id)
      .subscribe((result: any) => {
        this.projects = result;
      });
  }
}
