import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';

@Component({
  selector: 'app-campaign-summary',
  templateUrl: './campaign-summary.component.html',
  styleUrl: './campaign-summary.component.scss'
})
export class CampaignSummaryComponent {

  public ngo_id: string = null;
  public project_id: string = null;
  public campaign_id: string = null;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        this.campaign_id = decryptedParams.id;
        this.ngo_id = decryptedParams.ngo_id;
        this.project_id = decryptedParams.project_id;
      } else {
        // this.router.navigateByUrl('/dashboard/campaign');
        // console.error('Decryption failed');
      }
    });
  }
}
