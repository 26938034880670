import { Component } from '@angular/core';

@Component({
  selector: 'app-donor-list',
  templateUrl: './donor-list.component.html',
  styleUrl: './donor-list.component.scss',
})
export class DonorListComponent {
  public donors = [
    {
      id: 1,
      name: 'Anisa Islam',
      email: 'Anisa@gmail.com',
      team: [
        {
          name: 'Team A',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
        {
          name: 'Team B',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
      ],
      totalDonatedAmount: '৳ 60,5000',
      lastDonatedAmount: '৳ 12,0000',
      lastDonationDate: '12-09-2024 | 9:12 PM',
    },
    {
      id: 2,
      name: 'Imran Ahmed',
      email: 'AhmedImran@gmail.com',
      team: [
        {
          name: 'Team B',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
      ],
      totalDonatedAmount: '৳ 60,5000',
      lastDonatedAmount: '৳ 12,0000',
      lastDonationDate: '12-09-2024 | 9:12 PM',
    },
    {
      id: 3,
      name: 'Sabina Akhtar',
      email: 'Sabina@gmail.com',
      team: [
        {
          name: 'Team A',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
        {
          name: 'Team C',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
      ],
      totalDonatedAmount: '৳ 60,5000',
      lastDonatedAmount: '৳ 12,0000',
      lastDonationDate: '12-09-2024 | 9:12 PM',
    },
    {
      id: 4,
      name: 'Rizwan Islam',
      email: 'RizwanIslam@gmail.com',
      team: [
        {
          name: 'Team C',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
      ],
      totalDonatedAmount: '৳ 45,0000',
      lastDonatedAmount: '৳ 10,0000',
      lastDonationDate: '10-08-2024 | 8:10 PM',
    },
    {
      id: 5,
      name: 'Sair Bashir',
      email: 'BashirSair@gmail.com',
      team: [
        {
          name: 'Team A',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
      ],
      totalDonatedAmount: '৳ 50,5000',
      lastDonatedAmount: '৳ 11,0000',
      lastDonationDate: '15-10-2024 | 6:45 PM',
    },
    {
      id: 6,
      name: 'Saba Jamal',
      email: 'SabaJamal@gmail.com',
      team: [
        {
          name: 'Team B',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
      ],
      totalDonatedAmount: '৳ 40,0000',
      lastDonatedAmount: '৳ 9,0000',
      lastDonationDate: '01-09-2024 | 3:30 PM',
    },
    {
      id: 7,
      name: 'Yamin Khan',
      email: 'YaminKhan@gmail.com',
      team: [
        {
          name: 'Team C',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
      ],
      totalDonatedAmount: '৳ 42,5000',
      lastDonatedAmount: '৳ 10,5000',
      lastDonationDate: '20-08-2024 | 7:00 PM',
    },
    {
      id: 8,
      name: 'Shimu Haque',
      email: 'Shimu@gmail.com',
      team: [
        {
          name: 'Team B',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
      ],
      totalDonatedAmount: '৳ 48,0000',
      lastDonatedAmount: '৳ 10,0000',
      lastDonationDate: '28-09-2024 | 4:15 PM',
    },
    {
      id: 9,
      name: 'Ayan Malik',
      email: 'AyanMalik@gmail.com',
      team: [
        {
          name: 'Team A',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
        {
          name: 'Team C',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
      ],
      totalDonatedAmount: '৳ 70,0000',
      lastDonatedAmount: '৳ 14,0000',
      lastDonationDate: '05-11-2024 | 9:00 PM',
    },
    {
      id: 10,
      name: 'Riya Sen',
      email: 'RiyaSen@gmail.com',
      team: [
        {
          name: 'Team B',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
        {
          name: 'Team C',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
        {
          name: 'Team C',
          logo: '../../../../../assets/images/mobashwir_bhai.png',
        },
      ],
      totalDonatedAmount: '৳ 38,0000',
      lastDonatedAmount: '৳ 8,0000',
      lastDonationDate: '30-09-2024 | 1:50 PM',
    },
  ];
}
