import { Component, ViewChild } from '@angular/core'
import { JoinAsFundraiserComponent } from 'src/app/shared/components/ngo-campaign/join-as-fundraiser/join-as-fundraiser.component';
import {
  ApprovalCategoryEnum,
  ApprovalStatusEnum,
} from 'src/app/shared/enum/approval-category.enum';
import { ApprovalUpdateModel } from 'src/app/shared/model/approval.model';
import { ApprovalService } from 'src/app/shared/services/approval/approval.service';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { RichTextService } from 'src/app/shared/services/rich-text/rich-text.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import ValidationEngine from 'devextreme/ui/validation_engine';

@Component({
  selector: 'app-super-requested-campaigns',
  templateUrl: './super-requested-campaigns.component.html',
  styleUrl: './super-requested-campaigns.component.scss',
})
export class SuperRequestedCampaignsComponent {
  showDonationSummary: boolean = false;
  showMoreDonorExperienceButton: boolean = false;

  public activeTabIndex = 0;
  public searchKeyword = '';

  public filteredCampaigns: any[] = [];

  public showCampaignDetails: boolean = false;

  public ngoId: string = null;
  public projectId: string = null;
  public campaignId: string = null;

  public mainList: any[] = [];
  public allRequests: any = [];

  public campaign: any;

  public isCommentHasData: boolean = true;
  public comment_reply: any[] = [];

  @ViewChild(JoinAsFundraiserComponent)
  joinAsFundraiserComponent: JoinAsFundraiserComponent;

  public campaignList: any[] = [];

  // pop up

  public dayLeft: number | string;
  public showFundraisers: boolean = false;
  public rejectButtonClickd: boolean = false;
  public initialPopUp: boolean = true;
  public approvalStatusEnum = ApprovalStatusEnum;
  public approvalUpdate = new ApprovalUpdateModel();
  public requestId: string = null;

  constructor(
    private readonly donationService: DonationService,
    private readonly richTextService: RichTextService,
    public readonly utilitiesService: UtilitiesService,
    public readonly localStorageService: LocalStorageService,
    private readonly approvalService: ApprovalService
  ) {}

  ngOnInit(): void {
    this.getAllRequests();
  }

  public onTabChange(event: any) {
    this.activeTabIndex = event.component.option('selectedIndex');
    this.filterCampaigns();
  }

  public filterCampaigns(): void {
    const statuses = [
      ApprovalStatusEnum.PENDING,
      ApprovalStatusEnum.ACCEPTED,
      ApprovalStatusEnum.DECLINED,
    ];
    this.filteredCampaigns = this.mainList.filter(
      (campaign) => campaign.status === statuses[this.activeTabIndex]
    );
  }

  public showPopUp(
    ngoId: string,
    projectId: string,
    requestId: string,
    campaignId: string
  ): void {
    this.showCampaignDetails = !this.showCampaignDetails;
    this.ngoId = ngoId;
    this.projectId = projectId;
    this.campaignId = campaignId;
    this.requestId = requestId;

    this.getCampaignDonationById(this.ngoId, this.projectId, this.campaignId);
  }

  private getAllRequests() {
    this.allRequests = [];

    this.approvalService
      .getAllRequest(ApprovalCategoryEnum.CAMPAIGN)
      .subscribe({
        next: (result) => {
          this.mainList = result;
          this.filterCampaigns();
        },
      });
  }

  private getCampaignDonationById(
    ngo_id: string,
    project_id: string,
    campaign_id: string
  ) {
    this.utilitiesService.showSpinner(true);
    this.donationService
      .getDonationCampaignById(ngo_id, project_id, campaign_id)
      .subscribe({
        next: (result) => {
          this.onSuccess(result);
        },
        error: (err) => {
          this.utilitiesService.showSpinner(false);
        },
      });
  }

  private onSuccess(result) {
    this.utilitiesService.showSpinner(false);
    this.campaign = result;

    this.campaign.cover_img_vdo = JSON.parse(this.campaign?.cover_img_vdo);
    this.campaign.story_rich_text = this.richTextService.decodeHtml(
      this.campaign.story_rich_text
    );
    this.campaign.donation_summary = JSON.parse(this.campaign.donation_summary);

    const element = document.getElementById('campaign_story');
    element.innerHTML = this.campaign.story_rich_text;

    this.getDaysLeft(this.campaign.end_date);
  }

  private getDaysLeft(endDate: string) {
    if (this.campaign.end_date) {
      const now = new Date().getDate();
      const end = new Date(endDate).getDate();

      if (end >= now) {
        this.dayLeft = Math.ceil((end - now) / (1000 * 3600 * 24));
      } else {
        this.dayLeft = 0;
      }
    } else {
      this.dayLeft = 'Ongoing';
    }
  }

  public rejectedAction(id: string, action: ApprovalStatusEnum): void {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      this.takeAction(id, action);
    } else {
      console.log('validation error');
    }
  }

  public async takeAction(id: string, action: ApprovalStatusEnum) {
    this.rejectButtonClickd = false;
    this.showCampaignDetails = false;
    const response = await this.confirmAction(null, 'Yes');
    if (response.isConfirmed) {
      const body = {
        status: action,
        comments: this.approvalUpdate.comments,
      };
      this.approvalService.takeApprovalAction(id, body).subscribe({
        next: (result) => {
          let item = this.mainList.find((obj) => obj.id === id);
          item.status = action;
          this.filterCampaigns();
          this.approvalUpdate = new ApprovalUpdateModel();
          this.utilitiesService.showSwalWithToast('Approved', result.message);
        },
      });
    } else {
      this.showCampaignDetails = false;
      this.initialPopUp = true;
    }
  }

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }

  public rejectPopUp() {
    this.rejectButtonClickd = true;
  }

  public backExecute() {
    this.approvalUpdate.comments = null;
    this.rejectButtonClickd = false;
  }

  public load_more() {
    this.initialPopUp = false;
    this.campaign.donation_summary = JSON.parse(this.campaign.donation_summary);
    const element = document.getElementById('campaign_story');
    element.innerHTML = this.campaign.story_rich_text;
  }

  public handleClosePopup() {
    this.initialPopUp = true;
    this.showCampaignDetails = false;
  }
}
