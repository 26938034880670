import { Component, Input, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-ngo-info',
  templateUrl: './ngo-info.component.html',
  styleUrl: './ngo-info.component.scss',
})
export class NgoInfoComponent implements OnInit {
  public utilitiesService = UtilitiesService;

  @Input() ngo_details: any;

  public activeTab = {
    no: 1,
    obj: 'mission_vision',
    text: 'Mission & Vision',
  };
  public tabPane: any[] = [
    {
      no: 0,
      obj: 'about_us',
      text: 'About Us',
    },
    {
      no: 1,
      obj: 'mission_vision',
      text: 'Mission & Vision',
    },
    {
      no: 2,
      obj: 'our_story',
      text: 'Our Story',
    },
    {
      no: 3,
      obj: 'key_activities',
      text: 'Key Activities',
    },
    {
      no: 4,
      obj: 'award_img_url',
      text: 'Awards & Recognition',
    },
  ];

  constructor() {}
  
  ngOnInit(): void {
    this.ngo_details.award_img_url = UtilitiesService.getUrl(
      this.ngo_details.award_img_url
    );
  }

  public getActiveTab(tab: number): boolean {
    return tab === this.activeTab.no;
  }

  public setActiveTab(tab: number): void {
    this.activeTab = this.tabPane[tab];
  }
}
