import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'src/app/shared/components/not-found/not-found.component';
import { SAdminWorkplaceComponent } from './s-admin-workplace/s-admin-workplace.component';
import { NgoCampaignFeatureRequestComponent } from './ngo-campaign/ngo-campaign-feature-request/ngo-campaign-feature-request.component';
import { SAdminCampaignDetailsComponent } from './ngo-campaign/s-admin-campaign-details/s-admin-campaign-details.component';
import { AllResponseComponent } from './all-response/all-response.component';
import { ContactRequestListComponent } from './contact-request/contact-request-list/contact-request-list.component';
import { NgoClaimCreateListComponent } from './ngo-campaign/ngo-claim-create-list/ngo-claim-create-list.component';
import { AllNgoListComponent } from './ngo-management/all-ngo-list/all-ngo-list.component';
import { CampaignsListComponent } from './campaign-management/campaigns-list/campaigns-list.component';
import { RequestedCampaignsComponent } from './campaign-management/requested-campaigns/requested-campaigns.component';
import { SuperRequestedCampaignsComponent } from './campaign-management/super-requested-campaigns/super-requested-campaigns.component';
import { CampainSummaryComponent } from 'src/app/shared/components/ngo-campaign/campain-summary/campain-summary.component';
import { NgoDetailsComponent } from './ngo-management/ngo-details-page/ngo-details/ngo-details.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { CampaignSummaryComponent } from './campaign-summary/campaign-summary.component';
import { DonorDashboardComponent } from './donation-management/donor-managemnet/donor-dashboard/donor-dashboard.component';

const routes: Routes = [
  {
    path: 'campaign-list',
    component: CampaignListComponent,
    pathMatch: 'full',
  },
  // {
  //   path: 'create-new-campaign',
  //   component: CreateCampaignComponent,
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'campaign/edit',
  //   component: CreateCampaignComponent,
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'donations',
  //   component: DonationListComponent,
  //   pathMatch: 'full',
  // },
  {
    path: 'workplace',
    component: SAdminWorkplaceComponent,
    pathMatch: 'full',
  },
  {
    path: 'approval/ngo-claim-create-request',
    component: NgoClaimCreateListComponent,
    pathMatch: 'full',
  },
  {
    path: 'approval/campaign-feature-request',
    component: NgoCampaignFeatureRequestComponent,
    pathMatch: 'full',
  },
  {
    path: 'approval/campaign-details',
    component: SAdminCampaignDetailsComponent,
    pathMatch: 'full',
  },
  {
    path: 'approval/contact-requests',
    component: ContactRequestListComponent,
    pathMatch: 'full',
  },
  {
    path: 'ngo-management/all-ngos',
    component: AllNgoListComponent,
    pathMatch: 'full',
  },
  {
    path: 'ngo-management/ngo-details',
    component: NgoDetailsComponent,
    pathMatch: 'full',
  },
  {
    path: 'ngo-management/ngo-approval-request',
    component: NgoClaimCreateListComponent,
    pathMatch: 'full',
  },
  {
    path: 'all-donations',
    component: AllResponseComponent,
    pathMatch: 'full',
  },
  {
    path: 'campaign-management/all-donation-campaigns',
    component: CampaignsListComponent,
    pathMatch: 'full',
  },
  {
    path: 'campaign-management/all-featured-campaign',
    component: RequestedCampaignsComponent,
    pathMatch: 'full',
  },
  {
    path: 'campaign-management/all-requested-campaign',
    component: SuperRequestedCampaignsComponent,
    pathMatch: 'full',
  },
  {
    path: 'campaign-management/summary',
    component: CampaignSummaryComponent,
    pathMatch: 'full',
  },
  {
    path: 'donation-management/all-donor',
    component: DonorDashboardComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: 'workplace',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuperAdminRoutingModule {}
