<div class="main_div">
  <div class="mb-4">
    <span class="title_div" style="color: #0054a6">NGO/NPO Management / </span
    ><span class="title_div" style="color: black">Requested NGOs/NPOs</span>
  </div>

  <div class="header">
    <div class="header_text">
      <div class="donation_camp_text">Requested NGOs/NPOs</div>
    </div>
  </div>

  <!-- panel-title -->
  <div class="full_panel row">
    <div class="col-sm-12 col-lg-6 tab_div">
      <div
        class="tab"
        [class.active]="activeTab === 1"
        (click)="changeTab(1, approvalStatusEnum.PENDING)"
      >
        <img
          [src]="
            '/assets/images/icon/' +
            (activeTab === 1 ? 'pending_icon_active.svg' : 'pending_icon.svg')
          "
        />Pending
      </div>
      <div
        class="tab"
        [class.active]="activeTab === 2"
        (click)="changeTab(2, approvalStatusEnum.ACCEPTED)"
      >
        <img
          [src]="
            'assets/images/icon/' +
            (activeTab === 2 ? 'approved_icon_active.svg' : 'approved_icon.svg')
          "
        />Approved
      </div>
      <div
        class="tab"
        [class.active]="activeTab === 3"
        (click)="changeTab(3, approvalStatusEnum.DECLINED)"
      >
        <img
          [src]="
            'assets/images/icon/' +
            (activeTab === 3 ? 'rejected_icon_active.svg' : 'rejected_icon.svg')
          "
        />Rejected
      </div>
    </div>
    <div class="col-sm-12 col-lg-6 panel_right_side">
      <dx-text-box
        class="search_box"
        placeholder="Filter By keyword"
        [showClearButton]="true"
        (onInput)="onSearchKeywordChanged($event)"
        [buttons]="[
          {
            name: 'searchCampaign',
            location: 'before',
            options: {
              icon: 'find',
              elementAttr: {
                class: 'searchForm'
              }
            }
          }
        ]"
      >
      </dx-text-box>
    </div>
  </div>
  <!-- panel-title-end -->

  <!-- tab-panel-content -->
  <div class="panel_content_for_my_campaign">
    <div class="ngo_list">
      <div
        class="ngo_card"
        *ngFor="let ngo of activeDataSource; let i = index"
        (click)="openDetails(ngo.id, ngo.comment, ngo.attribute.ngo_id)"
      >
        <div class="row card_1st_div">
          <div class="col icon_div">
            <img
              class="icon_pic"
              [src]="
                ngo.attribute.logo_url?.url ||
                '/assets/images/claim_your_NGO.png'
              "
            />
            <div class="d-flex flex-column">
              <span class="title">{{ ngo.attribute.ngo_name }}</span
              ><span class="value">{{ "NGO" }}</span>
            </div>
          </div>
          <div class="col status_div justify-content-end">
            <span class="value">NGO Status: </span
            ><span
              class="status"
              [class.pending]="activeTab === 1"
              [class.accepted]="activeTab === 2"
              [class.rejected]="activeTab === 3"
            >
              {{ ngo.status }}</span
            >
          </div>
        </div>
        <div class="row card_1st_div border-0 m-0">
          <div class="col icon_div">
            <img
              class="icon_pic"
              [src]="
                ngo.attribute.user_profile_pic ||
                '/assets/images/round_profile.png'
              "
            />
            <div class="d-flex flex-column">
              <span class="title">{{
                ngo.attribute.creation_type === creationTypeEnum.CLAIMED
                  ? "Claimed By"
                  : "Register By"
              }}</span
              ><span class="value">{{ ngo.attribute.user_name }}</span>
            </div>
          </div>
          <div class="col icon_div">
            <img class="icon_pic" src="/assets/images/icon/date_icon.svg" />
            <div class="d-flex flex-column">
              <span class="title">Date</span
              ><span class="value">{{
                ngo.created_at | date : "dd MMM, yyyy"
              }}</span>
            </div>
          </div>
          <div class="col icon_div">
            <img
              class="icon_pic"
              src="/assets/images/icon/location_round_icon.svg"
            />
            <div class="d-flex flex-column">
              <span class="title">NGO Location</span
              ><span class="value">{{ ngo.attribute.address }}</span>
            </div>
          </div>
          <div class="col icon_div justify-content-end">
            <img class="icon_pic" src="/assets/images/icon/req_icon.svg" />
            <div class="d-flex flex-column">
              <span class="title">Req No</span
              ><span class="value">{{
                ngo.request_no | number : "3.0-0"
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- tab-panel-content-end -->

  <div
    *ngIf="mainList.length === 0"
    class="No_form_container d-flex flex-column align-items-center"
    style="margin-top: 50px"
  >
    <img
      src="./assets/images/icon/donation_colorful_icon.svg"
      alt="donation-image"
      title="donations"
    />
    <span class="no_form_workspace">No feature requests yet</span>
  </div>
</div>

<dx-popup
  [(visible)]="openPopUp"
  [width]="1010"
  height="auto"
  [maxHeight]="'95vh'"
  [showCloseButton]="true"
  [showTitle]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
  (onHidden)="loadMoreDetails(false)"
>
  <div class="popup_main">
    <div class="popup_div">
      <div class="popup_title">NGO Details</div>
      <div class="details_div">
        <div *ngIf="activeTab === 3">
          <div class="value" style="color: #d92d20; font-weight: 700">
            Application Rejected
          </div>
          <div class="popup_value" style="font-weight: 400">
            {{ currentComment }}
          </div>
        </div>

        <div>
          <div class="value">NGO Name</div>
          <div class="popup_value">{{ ngoDetails?.name }}</div>
        </div>
        <div>
          <div class="value">Govt. NGO Registration No</div>
          <div class="popup_value">{{ ngoDetails?.reg_no }}</div>
        </div>

        <div
          *ngIf="!loadMore"
          class="button_div text-decoration-underline cursor"
          (click)="loadMoreDetails(true)"
        >
          Load More Details
        </div>

        @if(ngoDetails?.creation_type === creationTypeEnum.CREATED && loadMore)
        {
        <div>
          <div class="value">Type of Business</div>
          <div class="popup_value">
            {{ ngoDetails?.business_type || "Not Available!" }}
          </div>
        </div>
        <div>
          <div class="value">Address</div>
          <div class="popup_value">
            {{ ngoDetails?.address || "Not Available!" }}
          </div>
        </div>
        <div>
          <div class="value">Phone</div>
          <div class="popup_value">
            {{ ngoDetails?.phone || "Not Available!" }}
          </div>
        </div>
        <div>
          <div class="value">Email</div>
          <div class="popup_value">
            {{ ngoDetails?.email || "Not Available!" }}
          </div>
        </div>
        <div>
          <div class="value">Size of Employee</div>
          <div class="popup_value">
            {{ ngoDetails?.no_of_employees || "Not Available!" }}
          </div>
        </div>
        <div>
          <div class="value">Cause Area</div>
          <div class="popup_value">
            {{ ngoDetails?.country || "Not Available!" }}
          </div>
        </div>
        <div>
          <div class="value">Year Founded</div>
          <div class="popup_value">
            {{ ngoDetails?.founding_year || "Not Available!" }}
          </div>
        </div>
        <div>
          <div class="value">Website</div>
          <div class="popup_value">
            {{ ngoDetails?.website || "Not Available!" }}
          </div>
        </div>
        <div>
          <div class="value">Mission & Vision</div>
          <div class="popup_value">
            {{ ngoDetails?.mission_vision || "Not Available!" }}
          </div>
        </div>
        <div>
          <div class="value">Our Story</div>
          <div class="popup_value">
            {{ ngoDetails?.our_story || "Not Available!" }}
          </div>
        </div>
        <div>
          <div class="value">Key Activities</div>
          <div class="popup_value">
            {{ ngoDetails?.key_activities || "Not Available!" }}
          </div>
        </div>
        <div>
          <div class="value">Logo/Profile</div>
          <div class="popup_value">
            @if(ngoDetails?.logo_url?.url) {
            <img class="w-100 my-3" [src]="ngoDetails?.logo_url?.url" />} @else
            {Not Available!}
          </div>
        </div>
        <div>
          <div class="value">Cover Image</div>
          <div class="popup_value">
            @if(ngoDetails?.ngo_profile_img_url?.url) {
            <img
              class="w-100 my-3"
              [src]="ngoDetails?.ngo_profile_img_url?.url"
            />} @else {Not Available!}
          </div>
        </div>
        } @if(loadMore) {
        <div>
          <div class="value">NGO Registration Certificate</div>
          @if(ngoDetails?.ngo_document_url?.resource_type === 'pdf') {
          <div class="pdf_div">
            <pdf-viewer
              [src]="
                'https://hub.sensor.buzz/wash-files/api/v1/file-serve/2fb836aa-95ca-4f99-9247-0e2e53e13007.pdf'
              "
              [rotation]="0"
              [original-size]="false"
              [show-all]="true"
              [fit-to-page]="false"
              [zoom]="zoomIndex"
              [zoom-scale]="'page-width'"
              [stick-to-page]="false"
              [render-text]="true"
              [external-link-target]="'blank'"
              [autoresize]="true"
              [show-borders]="false"
              style="width: 537px; height: 350px"
            ></pdf-viewer>
            <div class="d-flex flex-column gap-3">
              <img
                title="Zoom-in"
                class="cursor"
                src="/assets/images/icon/zoom_in_icon.svg"
                (click)="zoom(0.1)"
              /><img
                title="Zoom-out"
                class="cursor"
                src="/assets/images/icon/zoom_out_icon.svg"
                (click)="zoom(-0.1)"
              /><img
                title="Download"
                class="cursor"
                src="/assets/images/icon/download_round_icon.svg"
                (click)="
                  downloadFile(
                    'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'
                  )
                "
              />
            </div>
          </div>
          } @else {
          <div class="popup_value">
            @if(ngoDetails?.ngo_document_url?.url) {
            <img
              class="w-100 my-3"
              [src]="ngoDetails?.ngo_document_url?.url"
            />} @else {Not Available!}
          </div>
          }
        </div>
        <div>
          <div class="value">
            Proof of Identity (e.g., National ID, Passport, or Official ID)
          </div>
          @if(ngoDetails?.admin_nid_url?.resource_type === 'pdf') {
          <div class="pdf_div">
            <pdf-viewer
              [src]="
                'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'
              "
              [rotation]="0"
              [original-size]="false"
              [show-all]="true"
              [fit-to-page]="false"
              [zoom]="zoomIndex"
              [zoom-scale]="'page-width'"
              [stick-to-page]="false"
              [render-text]="true"
              [external-link-target]="'blank'"
              [autoresize]="true"
              [show-borders]="false"
              style="width: 537px; height: 350px"
            ></pdf-viewer>
            <div class="d-flex flex-column gap-3">
              <img
                title="Zoom-in"
                class="cursor"
                src="/assets/images/icon/zoom_in_icon.svg"
                (click)="zoom(0.1)"
              /><img
                title="Zoom-out"
                class="cursor"
                src="/assets/images/icon/zoom_out_icon.svg"
                (click)="zoom(-0.1)"
              /><img
                title="Download"
                class="cursor"
                src="/assets/images/icon/download_round_icon.svg"
                (click)="
                  downloadFile(
                    'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'
                  )
                "
              />
            </div>
          </div>
          } @else {
          <div class="popup_value">
            @if(ngoDetails?.admin_nid_url?.url) {
            <img class="w-100 my-3" [src]="ngoDetails?.admin_nid_url?.url" />}
            @else {Not Available!}
          </div>
          }
        </div>
        }
      </div>
      <div class="button_div">
        <button
          class="yellow-button-md"
          (click)="takeAction(false)"
          [disabled]="activeTab === 3"
        >
          Reject</button
        ><button
          class="blue-button-md"
          (click)="takeAction(true)"
          [disabled]="activeTab === 2"
        >
          Approve
        </button>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="rejectPopUp"
  [width]="600"
  height="360"
  [showCloseButton]="true"
  [showTitle]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="popup_main">
    <div class="popup_div">
      <div class="popup_title">Feedback</div>
      <div class="details_div">
        <div>
          <div class="value requiredMark">
            Write down why NGO Application is rejected
          </div>
          <dx-text-area
            class="input_field"
            [height]="134"
            placeholder="e.g. Message Body"
            [(ngModel)]="approvalBody.comments"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Message is required"
              >
              </dxi-validation-rule>
              <dxi-validation-rule
                type="stringLength"
                [max]="200"
                [trim]="false"
                message="200 characters maximum"
              >
              </dxi-validation-rule
            ></dx-validator>
          </dx-text-area>
        </div>
      </div>
      <div class="button_div">
        <button class="yellow-button-md" (click)="reject()">Send</button>
      </div>
    </div>
  </div>
</dx-popup>
