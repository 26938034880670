import { Component } from '@angular/core';
import {
  ApprovalCategoryEnum,
  ApprovalStatusEnum,
} from 'src/app/shared/enum/approval-category.enum';
import { CreationTypeEnum } from 'src/app/shared/enum/creation-type.enum';
import { ApprovalUpdateModel } from 'src/app/shared/model/approval.model';
import { ApprovalService } from 'src/app/shared/services/approval/approval.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { NgoService } from 'src/app/shared/services/ngo/ngo.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import ValidationEngine from 'devextreme/ui/validation_engine';

@Component({
  selector: 'app-ngo-claim-create-list',
  templateUrl: './ngo-claim-create-list.component.html',
  styleUrl: './ngo-claim-create-list.component.scss',
})
export class NgoClaimCreateListComponent {
  public approvalStatusEnum = ApprovalStatusEnum;
  public creationTypeEnum = CreationTypeEnum;

  public openPopUp: boolean = false;
  public loadMore: boolean = false;
  public rejectPopUp: boolean = false;
  public zoomIndex: number = 1;
  public ngoDetails: any;

  public activeTab: number = 1;
  public activeType: ApprovalStatusEnum = ApprovalStatusEnum.PENDING;
  public searchKeyword = '';

  public mainList: any[] = [];
  public activeList: any[] = [];

  private requestId: string;
  public currentComment: string;
  public approvalBody: ApprovalUpdateModel = new ApprovalUpdateModel();

  public sessionUser = this.localStorageService.getSessionUser();

  constructor(
    private readonly ngoService: NgoService,
    private readonly approvalService: ApprovalService,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.getAllRequests();
  }

  private getAllRequests() {
    this.activeList = [];

    this.approvalService.getAllRequest(ApprovalCategoryEnum.NGO).subscribe({
      next: (result) => {
        this.mainList = result;
        this.activeList = result.filter(
          (item) => item.status === this.activeType
        );
      },
    });
  }

  public changeTab(tab: number, type: ApprovalStatusEnum): void {
    this.activeTab = tab;
    this.activeType = type;
    this.activeList = this.mainList.filter(
      (data) => data.status === this.activeType
    );
  }

  public get activeDataSource(): any[] {
    if (!this.searchKeyword) {
      return this.activeList;
    } else {
      return this.activeList.filter((campaign) =>
        campaign.searchValue.toLowerCase().includes(this.searchKeyword)
      );
    }
  }

  public onSearchKeywordChanged(event: any) {
    this.searchKeyword = event.event.target.value.toLowerCase();
  }

  public openDetails(requestId: string, comment: string, ngoId: string): void {
    this.requestId = requestId;
    this.currentComment = comment;
    this.getNGODetails(ngoId);
  }

  private getNGODetails(ngoId: string): void {
    this.ngoService.getNgoById(ngoId).subscribe((result: any) => {
      this.ngoDetails = result;
      this.ngoDetails.admin_nid_url = this.utilitiesService.jsonParse(
        this.ngoDetails?.admin_nid_url
      );
      this.ngoDetails.logo_url = this.utilitiesService.jsonParse(
        this.ngoDetails?.logo_url
      );
      this.ngoDetails.ngo_document_url = this.utilitiesService.jsonParse(
        this.ngoDetails?.ngo_document_url
      );
      this.openPopUp = true;
    });
  }

  public async takeAction(action: boolean) {
    if (action) {
      this.approveRequest();
    } else {
      const response = await this.confirmAction(
        'You want to reject the request?',
        'Yes, Continue'
      );
      if (response.isConfirmed) {
        this.rejectPopUp = true;
      }
    }
  }

  public reject(): void {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      this.approvalBody.status = this.approvalStatusEnum.DECLINED;
      this.approveOrReject();
    }
  }

  private async approveRequest() {
    const response = await this.confirmAction(
      'You want to approve the request?',
      'Yes, Approve'
    );
    if (response.isConfirmed) {
      this.approveOrReject();
    }
  }

  private approveOrReject(): void {
    this.approvalService
      .takeApprovalAction(this.requestId, this.approvalBody)
      .subscribe({
        next: (res) => {
          this.getAllRequests();
          this.rejectPopUp = false;
          this.openPopUp = false;
          this.approvalBody = new ApprovalUpdateModel();
        },
      });
  }

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }

  public loadMoreDetails(value: boolean): void {
    this.loadMore = value;
  }

  public zoom(value: number): void {
    this.zoomIndex += value;
  }

  public downloadFile(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }
}
