import { DonationStatusEnum } from '../enum/donation-status.enum';

export class DonationStatusModel {
  status: DonationStatusEnum = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}
