<div class="main_div">
  <div class="header">
    <div class="header_text">
      <div class="donation_camp_text">Campaign Feature Requests</div>
    </div>
  </div>

  <!-- panel-title -->
  <div class="full_panel">
    <div class="col panel_right_side">
      <dx-text-box
        class="search_box"
        placeholder="Filter By keyword"
        [showClearButton]="true"
        (onInput)="onSearchKeywordChanged($event)"
        [buttons]="[
          {
            name: 'searchCampaign',
            location: 'before',
            options: {
              icon: 'find',
              elementAttr: {
                class: 'searchForm'
              }
            }
          }
        ]"
      >
      </dx-text-box>
    </div>
    <div>
      <dx-select-box
        width="200"
        [(ngModel)]="filterValue"
        [items]="[
          'ALL',
          approvalStatusEnum.ACCEPTED,
          approvalStatusEnum.PENDING,
          approvalStatusEnum.DECLINED
        ]"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        (onSelectionChanged)="filterValueChanged($event)"
      ></dx-select-box>
    </div>
  </div>
  <!-- panel-title-end -->

  <!-- tab-panel-content -->
  <div class="panel_content_for_my_campaign">
    <div class="campaign_list">
      <div *ngFor="let campaign of activeDataSource; let i = index">
        <div class="campaign_card">
          <div
            class="title_div"
            [routerLink]="['/admin/approval/campaign-details']"
            [queryParams]="{
              id: campaign?.approval_category_id,
              ngo_id: campaign?.ngo_id,
              project_id: campaign?.project_id
            }"
            title="Details"
          >
            <div class="cover_img_div">
              <img
                class="cover_image"
                [src]="campaign?.attribute?.cover_img_vdo"
                alt="campaign-cover-image"
                title="campaign-cover-image"
              />
            </div>
            <div class="title_subtitle">
              <div class="subtitle">{{ campaign?.attribute?.ngo_name }}</div>
              <div class="title">{{ campaign?.attribute?.title }}</div>
              <div class="subtitle" style="color: #939393">
                {{ campaign?.attribute?.subtitle }}
              </div>
            </div>
          </div>
          <div class="title_subtitle" style="width: 200px">
            <div class="status">Status: {{ campaign.status }}</div>
          </div>
          <div class="title_subtitle" style="min-width: 222px">
            <span class="form_date"
              >Created on:
              {{ campaign?.created_at | date : "dd MMM yyyy" }}</span
            >
            <div class="d-flex gap-2">
              <button
                [ngClass]="
                  campaign.status === approvalStatusEnum.DECLINED ||
                  campaign.status === approvalStatusEnum.PENDING
                    ? 'blue-button-md'
                    : 'yellow-button-md'
                "
                (click)="
                  campaign.status === approvalStatusEnum.ACCEPTED
                    ? takeAction(campaign.id, i, approvalStatusEnum.DECLINED)
                    : takeAction(campaign.id, i, approvalStatusEnum.ACCEPTED)
                "
              >
                {{
                  campaign.status === approvalStatusEnum.DECLINED ||
                  campaign.status === approvalStatusEnum.PENDING
                    ? "APPROVE"
                    : "DECLINE"
                }}
              </button>
              <button
                *ngIf="campaign.status === approvalStatusEnum.PENDING"
                class="yellow-button-md"
                (click)="
                  takeAction(campaign.id, i, approvalStatusEnum.DECLINED)
                "
              >
                DECLINE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- tab-panel-content-end -->

  <div
    *ngIf="allRequests.length === 0"
    class="No_form_container d-flex flex-column align-items-center"
    style="margin-top: 50px"
  >
    <img
      src="./assets/images/icon/donation_colorful_icon.svg"
      alt="donation-image"
      title="donations"
    />
    <span class="no_form_workspace">No feature requests yet</span>
  </div>
</div>
