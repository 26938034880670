import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';

@Component({
  selector: 'app-super-admin-menu',
  templateUrl: './super-admin-menu.component.html',
  styleUrl: './super-admin-menu.component.scss',
})
export class SuperAdminMenuComponent implements OnInit {
  public activeIndex: number;
  public activeSubmenuIndex: number;
  public menuOpen: boolean = true;
  public open = new Map<number, boolean>([
    [2, false],
    [3, false],
    [4, false],
  ]);
  public isOpen = false;
  public sessionUser: any;

  constructor(
    private readonly router: Router,
    public readonly localStorageService: LocalStorageService
  ) {}

  public ngOnInit(value: any = null) {
    const aclMenu = value || this.getACLFromUrl();
    this.activeIndex = aclMenu?.menu_index;
  }

  public toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  public toggleMenu(index: number): void {
    this.activeIndex = index;
    if (this.open.has(index)) {
      this.toggleOpen(index);
    }
  }

  public isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  public isSubmenuActive(index: number): boolean {
    const url = this.router.url;
    if (url === '/admin/approval/ngo-claim-create-request') {
      return index === 21;
    } else if (url.includes('/admin/approval/campaign')) {
      return index === 22;
    } else if (url === '/admin/approval/contact-requests') {
      return index === 23;
    } else if (url === '/admin/ngo-management/all-ngos') {
      return index === 31;
    } else if (url === '/admin/ngo-management/ngo-approval-request') {
      return index === 32;
    } else if(url === '/admin/campaign-management/all-donation-campaigns'){
      return index === 41;
    }else if(url === '/admin/campaign-management/all-featured-campaign'){
      return index === 42;
    }else if(url === '/admin/campaign-management/all-requested-campaign'){
      return index === 43;
    }else{
      return false;
    }
  }
  public setActiveIndex(index: number): void {
    this.activeSubmenuIndex = index;
  }

  private toggleOpen(index: number): void {
    const value = this.open.get(index);
    this.open.set(index, !value);
  }

  public openCloseMenu(): void {
    // this.menuOpen = !this.menuOpen;
  }

  private getACLFromUrl(): any {
    const value = this.router.url;
    if (value === '/admin/workplace') {
      return { menu_index: 1 };
    } else if (value.includes('admin/approval')) {
      this.open[2] = true;
      var myCollapse = document.getElementById('submenu1');
      myCollapse.classList.add('show');

      return { menu_index: 2 };
    } else if (value.includes('admin/ngo-management')) {
      this.open[3] = true;
      var myCollapse = document.getElementById('submenu2');
      myCollapse.classList.add('show');

      return { menu_index: 3 };
    }else if (value.includes('admin/campaign-management')) {
      this.open[4] = true; 
      var myCollapse = document.getElementById('submenu3');
      myCollapse.classList.add('show');

      return { menu_index: 4 };
    }else if (value === '/admin/donation-list') {
      return { menu_index: 5 };
    } else {
      return null;
    }
  }
}
