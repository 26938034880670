import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { NgxSpinnerService } from 'ngx-spinner';
import { DonationStatusModel } from 'src/app/shared/model/donation-status.model';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-all-response',
  templateUrl: './all-response.component.html',
  styleUrl: './all-response.component.scss',
})
export class AllResponseComponent {
  public donationList: any = [];
  public total_size: number = null;
  private flag: boolean = true;

  public searchIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/search_icon.svg',
    stylingMode: 'text',
  };

  public searchButton: DxButtonTypes.Properties = {
    text: 'Search',
    stylingMode: 'text',
    onClick: () => {
      // this.onSearchClick();
    },
  };

  public statusOptions = ['RECEIVED', 'DISBURSED'];
  private isResetting: boolean = false;

  public statusModel: DonationStatusModel = new DonationStatusModel();

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly donationService: DonationService,
    private readonly utilitiesService: UtilitiesService
  ) {
    this.getAllDonationList(1, 3);
    // if(this.total_size > 3){
    //   this.getAllDonationList(2,this.total_size-3);
    // }
  }

  ngOnInit(): void {}
  private getAllDonationList(page: number, size: number) {
    this.spinner.show();
    this.donationService.getAllDonationForSuperAdmin(page, size).subscribe({
      next: (result) => {
        this.utilitiesService.showSpinner(false);
        const formattedData = result.data.map((item) => {
          if (item.created_at) {
            const date = new Date(item.created_at);
            const options: Intl.DateTimeFormatOptions = {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            };
            item.created_at = date.toLocaleDateString('en-US', options);
          }
          return item;
        });
        this.donationList = formattedData;
        this.total_size = result.total;
        if (this.flag) {
          this.flag = false;
          this.getAllDonationList(1, this.total_size);
        }
      },
      error: (err) => {
        this.utilitiesService.showSpinner(false);
        this.utilitiesService.showSwal(
          'error',
          'Error to fetching the Donation List'
        );
      },
    });
  }

  public async onStatusChange(event: any, donation: any) {
    if (this.isResetting) {
      this.isResetting = false;
      return;
    }
    const newStatus = event.value;
    const confirmed = await this.confirmAction(
      `Change status to ${newStatus}?`,
      'Confirm'
    );
    if (confirmed.isConfirmed) {
      this.statusModel.status = newStatus;
      if (this.statusModel.status === donation.status) {
        this.utilitiesService.showSwal(
          'warning',
          `Donation status Already ${newStatus}?`
        );
        this.isResetting = true;
        event.component.option('value', null);
      } else {
        this.statusUpdate(donation.id);
        this.donationList = this.donationList.map((x) =>
          x.id === donation.id ? { ...x, status: newStatus } : x
        );
      }
    } else {
      this.isResetting = true;
      event.component.option('value', null);
    }
  }

  private statusUpdate(id: string) {
    this.spinner.show();
    this.donationService.updateStatus(id, this.statusModel).subscribe({
      next: (res) => {
        this.spinner.hide();
        this.utilitiesService.showSwal(
          'success',
          'Donation Status Changed successfully!'
        );
      },
      error: (err) => {
        this.spinner.hide();
        this.utilitiesService.showSwal(
          'error',
          'Error changing the Donation status'
        );
      },
    });
  }

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }
}
